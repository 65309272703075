import { useDispatch } from 'react-redux';
import { userActions } from '../store/auth-slice';
import { axiosPrivate } from '../apis/backend';

const useGetUserInfo = () => {
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    await axiosPrivate.get('/user/authedInfo')
      .then(response => {
        dispatch(userActions.setUser(response?.data?.payload?.userInfo));
      })
      .catch(error => {
        if (error?.response?.data?.errCode) dispatch(userActions.setUser({ roles: [] }));
      })
  };

  return getUserInfo;
};

export default useGetUserInfo;