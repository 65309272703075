import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from '@mui/material';

export const ConfirmationModal = ({
    open,
    setModalOpen,
    handleConfirm,
    title,
    description,
    isLoading,
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle fontSize="2rem" id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText fontSize="1.5rem" id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() => setModalOpen(false)}
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            variant="contained"
                            size="large"
                            color="primary"
                            style={{ marginLeft: '3rem' }}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};
