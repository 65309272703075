import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import Alert from '../../components/Alert/Alert';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';
const ErrorPage = ({ error }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alertData, setAlertData] = useState();

  useEffect(() => {
    if (error === '403') {
      setAlertData({
        severity: "info",
        title: "Unauthorized",
        message: `${t("We are sorry, but you do not have permission to view this page.")}`,
        actions: [{ name: "Return Home", onClick: () => { navigate('/'); } }]
      });
    } else if (error === '404') {
      setAlertData({
        severity: "info",
        title: "Not Found",
        message: `${t("We are sorry, but the page you requested does not exist.")}`,
        actions: [{ name: "Return Home", onClick: () => { navigate('/'); } }]
      });
    };
  }, [error])

  return (
    <div className="Error">
      <Header />
      <Container maxWidth="lg" sx={{ marginTop: '36px' }}>
        <Alert data={alertData} />
      </Container>
      <Footer />
    </div>
  );
};

export default ErrorPage;