// To disconnect test data and connect backend, follow the comment flagged with CONNECT.


import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Popover,
  Select,
  Snackbar,
  Stack,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';  // Include in previous import?
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import Menu from '@mui/material/Menu'; // Corrected import
import MenuItem from '@mui/material/MenuItem'; // There must be a faster way to do this
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Input from '@mui/material/Input';
// import ExcelIcon from 'vscode-icons/dist/icons/file_type_excel.svg';  // Import the Excel icon   (Would require a dependency)

import Papa from 'papaparse';

import {
  Button as FCIButton,
  Footer,
  Header,
  MyResourcesList,
  StyledDropzone,
  HoursModal,
  PendingResourcesList,
  UsersList,
  ApprovedResourcesList,
  Map,
} from '../../components';

import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';
import { axiosPrivate } from '../../apis/backend';


const ManageUsers = () => {
  const { t } = useTranslation();
  const fileInputRef = React.useRef();
  const user = useSelector((state) => state.auth.user);


  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }


  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }



  // Sam's attempt to get the list of resources but it's roles
  // Why is the erronious request showing up twice?
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get('/admin/users');
      setUsers(response.data.payload.users);
    } catch (error) {
      console.error('Error during authentication:', error.response?.data?.errMsg || error.message);
    }
  };

  useEffect(() => {
    getUsers()
  }, []);


  const setApproval = async (userId, roles) => {
    const reqBody = {
      "userId": userId,   // should be userId? or id? Not even coming through...
      "removeRoles": ["100001", "100002", "100100", "100801", "100901", "100999"],
      "addRoles": roles
    }
    handleMenuClose2(userId);
    try {
      handleMenuClose2(userId);

      await axiosPrivate.put("/admin/user/roles", reqBody);
      getUsers();  // Updates all the users after role is changed
    }
    catch (error) {
      console.error("Error during changing resource status:", error.response?.data?.errMsg || error.message)
    }
  }

  const approvals = [
    "Admin",
    "Approved",
    "Pending",
    "Suspended"
  ]


  const testUsers = [
    {
      "firstName": "First1",
      "lasttName": "Last1",
      "email": "first1.last1@floridainnovation.org",
      "opid": "fb06a9ea-b085-4627-9f5b-1f58197bc58b",
      "roles": ["100001", "100002", "100100"]
    },
    {
      "firstName": "First2",
      "lasttName": "Last2",
      "email": "first2.last2@floridainnovation.org",
      "opid": "a8bb77e7-b20f-469c-abf9-fb20e6fbfd3e",
      "roles": ["100001", "100002", "100100", "100901"]
    },
    {
      "firstName": "First3",
      "lasttName": "Last3",
      "email": "first3.last3@floridainnovation.org",
      "opid": "6d6a3d37-77d9-41b2-a0be-1625f6917186",
      "roles": ["100001", "100002", "100100", "100999"]
    },
  ]


  function getRole(roles) {
    if (roles.includes("100801")) {
      return "Suspended"
    }
    else if (roles.includes("100901") || roles.includes("100999")) {
      return "Admin"
    }
    else if (roles.includes("100100")) {
      return "Approved"
    }
    else {
      return "Pending"
    }
  }

  testUsers.forEach((user, index) => {
    user.access = getRole(user.roles);
  })

  // Admin confirmation dialog control
  const [adminConfirmationOpen, setAdminConfirmationOpen] = React.useState(false);
  const [userToPromote, setUserToPromote] = React.useState(null);

  const handleAdminConfirmationOpen = (id) => {
    setUserToPromote(id);
    setAdminConfirmationOpen(true);

  };

  const handleAdminConfirmationClose = () => {
    setAdminConfirmationOpen(false);
  };


  const buttonTextStyle = {
    fontFamily: "Nunito",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "none"
  }


  // Dropdown menu controls

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [menuAnchors, setMenuAnchors] = useState({});

  const handleMenuClick2 = (event, id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget
    }));
  };

  const handleMenuClose2 = (id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: null
    }))
  };



  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle the search, e.g., make an API request
  };


  return (
    <>
      <Box sx={{ py: '30px' }}>
        <Container component="main" maxWidth="lg" style={{ border: '1px solid #CACCD3', borderRadius: '10px', overflow: "auto" }} >
          <Box sx={{
            mb: '2rem',
            marginTop: '2rem'
          }}>
            <Box>  {/*What does this one even do?*/}
              <div style={{
                display: 'flex',
                justifyContent: 'space-between', // This spreads out the child elements
                alignItems: 'center', // This vertically centers the items in the container
                width: '100%' // Ensures the container takes full width
              }}>
                <div style={{
                  'marginLeft': '10px',
                  'font-family': 'Nunito',
                  'font-size': '28px',
                  'font-style': 'normal',
                  'font-weight': "700",
                  'line-height': '44px'
                }}>
                  {t('Manage')}  {t('User.Plural')}
                </div>

              </div>

              <br />
              <Container>
                <form onSubmit={handleSearchSubmit}>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />   How to get this inside the search? */}
                    <TextField
                      // label="Search"
                      variant="outlined"
                      fullWidth
                      value={searchText}
                      onChange={handleSearchChange}
                      placeholder={`${t('Search for a listing here...')}`}
                      // style={{ textAlign: 'right' }}

                      InputProps={{
                        style: {
                          // inputComponent: <Input style={{ textAlign: 'right' }} />,
                          borderRadius: '3rem',
                          fontSize: '1.5rem',
                          height: '4.5rem'
                          // marginLeft: '',
                          // paddingLeft: "15px",
                          // textAlign: "right"

                        },
                        startAdornment: (
                          <Box style={{ marginRight: "10px", marginTop: "5px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g clip-path="url(#clip0_4633_3614)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2C9.1446 2.00012 7.80887 2.32436 6.60427 2.94569C5.39966 3.56702 4.3611 4.46742 3.57525 5.57175C2.78939 6.67609 2.27902 7.95235 2.08672 9.29404C1.89442 10.6357 2.02576 12.004 2.46979 13.2846C2.91382 14.5652 3.65766 15.7211 4.63925 16.6557C5.62084 17.5904 6.81171 18.2768 8.11252 18.6576C9.41333 19.0384 10.7864 19.1026 12.117 18.8449C13.4477 18.5872 14.6975 18.015 15.762 17.176L19.414 20.828C19.6026 21.0102 19.8552 21.111 20.1174 21.1087C20.3796 21.1064 20.6304 21.0012 20.8158 20.8158C21.0012 20.6304 21.1064 20.3796 21.1087 20.1174C21.111 19.8552 21.0102 19.6026 20.828 19.414L17.176 15.762C18.164 14.5086 18.7792 13.0024 18.9511 11.4157C19.123 9.82905 18.8448 8.22602 18.1482 6.79009C17.4517 5.35417 16.3649 4.14336 15.0123 3.29623C13.6597 2.44911 12.096 1.99989 10.5 2ZM4.00001 10.5C4.00001 8.77609 4.68483 7.12279 5.90382 5.90381C7.1228 4.68482 8.7761 4 10.5 4C12.2239 4 13.8772 4.68482 15.0962 5.90381C16.3152 7.12279 17 8.77609 17 10.5C17 12.2239 16.3152 13.8772 15.0962 15.0962C13.8772 16.3152 12.2239 17 10.5 17C8.7761 17 7.1228 16.3152 5.90382 15.0962C4.68483 13.8772 4.00001 12.2239 4.00001 10.5Z" fill="#666E87" />
                              </g>
                              <defs>
                                <clipPath id="clip0_4633_3614">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Box>
                        ),
                      }}
                    />
                  </Box>

                </form>
              </Container>
              {/* Would a virtualized table be better? */}

              <div style={{ height: '10px' }} />


              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div style={{ ...buttonTextStyle }}>{t('USER NAME')} <ArrowDropUpIcon /></div>
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ ...buttonTextStyle }}>{t('Email').toUpperCase()} <ArrowDropUpIcon /></div>
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ ...buttonTextStyle }}>{t('ACCESS')} <ArrowDropUpIcon /></div>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{ ...buttonTextStyle, '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div style={{ ...buttonTextStyle, fontSize: 14, fontWeight: 600 }}>
                          {user.firstName}  {" "}  {user.lastName}
                        </div>
                      </TableCell>
                      <TableCell align="right">

                        <div style={{ ...buttonTextStyle, fontSize: 14, fontWeight: 600 }}>
                          {user.email}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <Button endIcon={<ArrowDropDownIcon />} onClick={(event) => handleMenuClick2(event, user.userId)} variant="outlined" size="large" sx={{ ...buttonTextStyle, 'marginRight': '10px', 'border-radius': '8px', "border-color": "#0052CC", "color": "#0052CC", "borderWidth": "2px", '&:hover': { borderWidth: '2px', }, '&:active': { borderWidth: '2px', } }}>
                          {t(`${getRole(user.roles)}`)}
                        </Button>
                        <Menu
                          anchorEl={menuAnchors[user?.userId]}
                          open={Boolean(menuAnchors[user?.userId])}
                          onClose={() => handleMenuClose2(user.userId)}
                        >

                          <MenuItem onClick={() => setApproval(user.userId, ["100001", "100002", "100100"])}   /* needs a new onClick*/>
                            {
                              (user.access === "Approved") ?
                                (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none" style={{ visibility: 'hidden' }}>
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                )
                            }
                            <Typography sx={{ ...buttonTextStyle, color: "#0052CC", marginLeft: "10px" }}>{t('Approved')}</Typography>
                          </ MenuItem>

                          <MenuItem onClick={() => setApproval(user.userId, ["100001", "100002"])}   /* needs a new onClick. Also, what's this role.id thing? Probably a relic. */>
                            {
                              (user.access === "Pending") ?
                                (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none" style={{ visibility: 'hidden' }}>
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                )
                            }
                            <Typography sx={{ ...buttonTextStyle, color: "#0052CC", marginLeft: "10px" }}>{t('Pending')}</Typography>
                          </ MenuItem>

                          <MenuItem onClick={() => handleAdminConfirmationOpen(user.userId)}   /* needs a new onClick. Also, what's this role.id thing? Probably a relic. */>
                            {
                              (user.access === "Admin") ?
                                (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none" style={{ visibility: 'hidden' }}>
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                )
                            }
                            <Typography sx={{ ...buttonTextStyle, color: "#0052CC", marginLeft: "10px" }}>{t('Admin')}</Typography>
                          </ MenuItem>

                          <MenuItem onClick={() => setApproval(user.userId, ["100001", "100002", "100801"])}   /* needs a new onClick. Also, what's this role.id thing? Probably a relic. */>
                            {
                              (user.access === "Suspended") ?
                                (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none" style={{ visibility: 'hidden' }}>
                                    <path d="M9.98844 17.5615C9.74628 17.8037 9.35367 17.8037 9.11151 17.5615L4.56248 13.0125C4.16897 12.619 4.16897 11.981 4.56248 11.5875C4.95598 11.194 5.59397 11.194 5.98748 11.5875L9.54998 15.15L18.0125 6.68751C18.406 6.294 19.044 6.294 19.4375 6.68751C19.831 7.08101 19.831 7.719 19.4375 8.11251L9.98844 17.5615Z" fill="#0052CC" />
                                  </svg>
                                )
                            }
                            <Typography sx={{ ...buttonTextStyle, color: "#EE1313", marginLeft: "10px" }}>{t('Suspended')}</Typography>
                          </ MenuItem>
                        </Menu>
                        {/* </Popover> */}
                      </TableCell>
                    </TableRow>
                  )
                  )}
                </TableBody>
              </Table>
              <div style={{ height: '40px' }} />
              <br />
              <br />
              <IconButton>
                <ChevronLeftIcon sx={{ fontSize: 30 }} />
              </IconButton>

              <IconButton>
                <ChevronRightIcon sx={{ fontSize: 30 }} />
              </IconButton>


            </Box>

          </Box>

        </Container >
      </Box >


      <Dialog open={adminConfirmationOpen} onClose={handleAdminConfirmationClose}>
        <DialogTitle>
          <div style={{
            'marginLeft': '10px',
            'font-family': 'Nunito',
            'font-size': '28px',
            'font-style': 'normal',
            'font-weight': "700",   // linked in wrong place
            "display": "flex"
          }}>
            {t('Are you sure?')}
            <div style={{ marginLeft: 'auto', marginTop: '-10px' }}>
              <IconButton onClick={handleAdminConfirmationClose}>
                <CloseIcon style={{ fontSize: '38px', color: "black" }} />
              </IconButton>
            </div>
          </div>

        </DialogTitle>
        <Typography sx={{ ...buttonTextStyle, paddingLeft: '30px', paddingRight: '30px', fontWeight: "350", fontSize: '18px', color: 'black' }}>{t('This will allow the user you selected to approve resources and manage other users.')}

        </Typography>

        <br />
        <br />
        <Button onClick={() => { setApproval(userToPromote, ["100001", "100002", "100100", "100901"]); handleAdminConfirmationClose(); }} type="submit" variant="contained" size="large" sx={{ ...buttonTextStyle, "marginLeft": "30px", 'marginRight': '30px', 'border-radius': '8px', 'background': '#0052CC' }}    /* Needs an onClick to actually promote the user */>
          {t('Promote to administrator')}
        </Button>
        <br />
        <br />
      </Dialog>
    </>
  );
}

export { ManageUsers };


