import React from 'react';
import PropTypes from 'prop-types';
import { PopupText } from '../../resources/text';
import { Button } from '../';
import modalImage from '../../resources/LocationModal-cover.png';
import Cookies from 'universal-cookie';
import '../../styles/main.css';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export const LocationModal = ({ image, locationModalHandler, setlocationPermission }) => {
    const { t } = useTranslation();
    //STATES
    // FUNCTIONS
    const cookies = new Cookies();
    const primaryHandler = () => {
        locationModalHandler();
        const successCallback = (position) => {
            const location = { lat: position.coords.latitude, lng: position.coords.longitude };
            cookies.set('location', JSON.stringify(location), { path: '/' });
            setlocationPermission(true);
            localStorage.setItem('locationPermissionGranted', 'true', { path: '/' });
        };
        const errorCallback = (error) => {
            if (error.code === 1) {
                alert('Please allow your location access');
            }
        };
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
            enableHighAccuracy: true,
            timeout: 5000,
        });
        locationModalHandler();
    };
    const rejectHandler = () => {
        localStorage.setItem('locationPermissionDenied', 'true', { path: '/' });
        locationModalHandler();
    };

    return (
        <>
            <div className="location-modal__backdrop"></div>
            <div className="location-modal__card">
                <img alt="" className="location-modal__img" src={image} />

                <div className="location-modal__content">
                    <>
                        <div className="location-modal__main">{PopupText()}</div>
                        <div className="location-modal__buttons-wrapper--primary">
                            <Button
                                onClick={primaryHandler}
                                size="medium"
                                label={t('Share my location')}
                            />
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    // onModalChange();
                                    rejectHandler();
                                }}
                                size="medium"
                                label={t('Do not share my location')}
                            />
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};

LocationModal.propTypes = {
    primary: PropTypes.bool,
    pages: PropTypes.number,
    visible: PropTypes.bool,
    /**
     * This takes an SVG or Image component
     */
    image: PropTypes.string,
};

LocationModal.defaultProps = {
    primary: true,
    pages: 3,
    visible: true,
    image: modalImage,
};
