import React from 'react';

function HealthGraphic() {
  return (
    <svg
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3857 54.4H34.3475C34.6718 54.4 34.9928 54.3344 35.2924 54.207C35.592 54.0795 35.8642 53.8927 36.0935 53.6572C36.3228 53.4218 36.5047 53.1422 36.6288 52.8345C36.7529 52.5268 36.8167 52.197 36.8167 51.864V48.7686C36.2169 44.0415 36.2593 39.859 36.8167 34.8741V32.0926L21.5395 29.6133L17.9165 43.6282V51.864C17.9165 52.5366 18.1767 53.1816 18.6398 53.6572C19.1029 54.1328 19.7309 54.4 20.3857 54.4Z"
        fill="#FFBE71"
      />
      <path
        d="M36.8166 34.873H24.4266C23.9106 34.873 23.4158 35.0835 23.0508 35.4583C22.6859 35.833 22.4808 36.3412 22.4807 36.8712V46.7685C22.4808 47.2986 22.6858 47.8069 23.0507 48.1817C23.4157 48.5565 23.9106 48.7671 24.4266 48.7671H36.8166V34.873Z"
        fill="white"
      />
      <path
        d="M33.6845 40.2209H31.3637V37.8374H28.2505V40.2209H25.9299V43.4185H28.2505V45.802H31.3637V43.4185H33.6845V40.2209Z"
        fill="#FF5856"
      />
      <path
        d="M47.4669 48.7676H51.7309C52.0909 48.7676 52.4474 48.8404 52.7801 48.982C53.1127 49.1235 53.4149 49.3309 53.6695 49.5925C53.9241 49.854 54.126 50.1644 54.2637 50.5061C54.4015 50.8478 54.4723 51.214 54.4722 51.5838C54.4722 52.3305 54.1834 53.0467 53.6693 53.5748C53.1552 54.1028 52.4579 54.3995 51.7309 54.3995H47.4669C47.0632 52.3456 46.9667 50.4264 47.4669 48.7676Z"
        fill="#C9DFFA"
      />
      <path
        d="M43.2029 48.7676H47.4669V54.3989H43.2029C42.4759 54.3989 41.7788 54.1023 41.2647 53.5744C40.7507 53.0464 40.4618 52.3304 40.4617 51.5838C40.4616 51.214 40.5325 50.8478 40.6702 50.5061C40.8079 50.1645 41.0098 49.854 41.2644 49.5925C41.5189 49.331 41.8211 49.1236 42.1537 48.982C42.4863 48.8405 42.8428 48.7676 43.2029 48.7676Z"
        fill="#86B7FF"
      />
      <path
        d="M49.9488 42.1287C48.9567 42.6891 47.8125 42.8982 46.693 42.7237C45.5736 42.5491 44.5413 42.0007 43.7558 41.1632L42.4629 38.749C42.1956 37.6194 42.2979 36.4308 42.7539 35.3669C43.21 34.303 43.9945 33.4229 44.9861 32.8626C45.9778 32.3024 47.1215 32.0931 48.2405 32.2672C49.3596 32.4412 50.3916 32.9889 51.1774 33.8256L52.4703 36.24C52.7383 37.3697 52.6365 38.5587 52.1808 39.6231C51.725 40.6875 50.9406 41.568 49.9488 42.1287Z"
        fill="#C9DFFA"
      />
      <path
        d="M42.4639 38.749C42.68 39.6566 43.1259 40.4893 43.7566 41.1627L52.4697 36.2399C52.2537 35.3323 51.8078 34.4996 51.1773 33.8262L42.4639 38.749Z"
        fill="#86B7FF"
      />
      <path d="M36.8167 27.647H17.9165V32.0916H36.8167V27.647Z" fill="#FC9658" />
      <path
        d="M23.1104 30.2147H38.5494V26.796C38.5494 26.2719 38.3467 25.7693 37.9859 25.3987C37.6251 25.0281 37.1357 24.8199 36.6255 24.8198H25.1992C24.1597 26.6617 23.3683 28.4719 23.1104 30.2147Z"
        fill="#FFBE71"
      />
      <path
        d="M18.1081 24.8198C17.5979 24.8199 17.1086 25.0281 16.7478 25.3987C16.387 25.7693 16.1843 26.2719 16.1843 26.796V30.2147H23.11L25.1988 24.8198H18.1081Z"
        fill="#FC9658"
      />
      <path
        d="M21.2637 13.0233L25.9627 1.76172"
        stroke="#9397AE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.1043 27.647H17.9165V43.6273L24.1043 27.647Z" fill="#FC9658" />
      <path
        d="M20.6214 11.3785L22.9959 12.3461C23.1624 12.414 23.2959 12.5471 23.367 12.716C23.438 12.885 23.4408 13.0761 23.3748 13.2471L22.5799 15.3049L18.9491 13.8254L19.7439 11.7678C19.81 11.5967 19.9396 11.4596 20.1041 11.3866C20.2686 11.3136 20.4546 11.3108 20.6212 11.3786L20.6214 11.3785Z"
        fill="#86B7FF"
      />
      <path
        d="M18.89 13.3352L22.9501 14.9896C23.0911 15.0471 23.2198 15.1325 23.3287 15.2411C23.4376 15.3496 23.5246 15.4791 23.5848 15.6222C23.6449 15.7653 23.6771 15.9192 23.6794 16.075C23.6816 16.2309 23.654 16.3856 23.598 16.5305L22.2392 20.0484L16.0309 17.5186L17.3896 14.0012C17.4455 13.8562 17.5287 13.724 17.6344 13.6121C17.74 13.5002 17.8661 13.4107 18.0055 13.3489C18.1448 13.287 18.2947 13.254 18.4464 13.2516C18.5982 13.2493 18.7489 13.2777 18.89 13.3352Z"
        fill="white"
      />
      <path
        d="M18.6058 13.2194L23.2343 15.1054C23.338 15.1477 23.4326 15.2105 23.5127 15.2903C23.5928 15.3702 23.6568 15.4654 23.701 15.5706C23.7453 15.6759 23.7689 15.789 23.7706 15.9036C23.7723 16.0182 23.7519 16.1321 23.7108 16.2386L22.2392 20.0484L16.0309 17.5186L17.5023 13.7093C17.5434 13.6027 17.6046 13.5054 17.6823 13.4231C17.76 13.3408 17.8528 13.275 17.9553 13.2295C18.0577 13.184 18.1679 13.1597 18.2796 13.1579C18.3912 13.1562 18.5021 13.1771 18.6058 13.2194Z"
        fill="#689CEB"
      />
      <path
        d="M14.7417 44.3161L5.29077 40.4645L14.1623 17.4985C14.2264 17.3325 14.3217 17.1811 14.4428 17.053C14.5639 16.9248 14.7083 16.8224 14.8679 16.7516C15.0275 16.6808 15.1992 16.643 15.373 16.6403C15.5468 16.6376 15.7195 16.6701 15.8811 16.736L22.8711 19.5843C23.0327 19.6501 23.1802 19.7479 23.305 19.8722C23.4299 19.9965 23.5297 20.1449 23.5987 20.3088C23.6677 20.4728 23.7046 20.649 23.7073 20.8276C23.71 21.0062 23.6784 21.1836 23.6143 21.3496L22.5581 24.0843C22.4115 24.2274 22.2969 24.4016 22.2223 24.5947C22.1477 24.7879 22.1148 24.9954 22.126 25.2028L21.2275 27.5289C20.9763 27.8481 20.8256 28.2383 20.7954 28.6476L19.8961 30.973C19.6265 31.2808 19.4732 31.6776 19.464 32.0915L18.5655 34.4176C18.3201 34.7402 18.1701 35.1284 18.1336 35.5361L17.235 37.8624C17.0072 38.1951 16.8592 38.5783 16.803 38.9809L14.7417 44.3161Z"
        fill="#C9DFFA"
      />
      <path
        d="M11.2559 43.8247L8.15723 42.562L5.83085 48.5848L8.92949 49.8474L11.2559 43.8247Z"
        fill="#689CEB"
      />
      <path
        d="M2.9671 47.0675L12.0267 50.7592C12.3335 50.8842 12.5793 51.1292 12.7101 51.4404C12.841 51.7515 12.8461 52.1033 12.7244 52.4184L11.9592 54.3994L0.5865 49.7651L1.3517 47.7841C1.47339 47.469 1.71196 47.2165 2.0149 47.0822C2.31785 46.9478 2.66036 46.9425 2.9671 47.0675Z"
        fill="#86B7FF"
      />
      <path
        d="M15.6048 43.257L5.36932 39.0861C4.90053 38.8951 4.36974 39.1306 4.18375 39.6121L3.63321 41.0374C3.44723 41.5188 3.67649 42.064 4.14527 42.255L14.3808 46.4259C14.8495 46.6169 15.3803 46.3815 15.5663 45.9L16.1169 44.4747C16.3029 43.9932 16.0736 43.448 15.6048 43.257Z"
        fill="#86B7FF"
      />
      <path
        d="M20.615 23.2919C20.5434 23.2624 20.4669 23.2476 20.3898 23.2486C20.3126 23.2495 20.2364 23.2661 20.1655 23.2973C20.0946 23.3286 20.0304 23.3739 19.9767 23.4307C19.9229 23.4875 19.8805 23.5547 19.8521 23.6283C19.8236 23.702 19.8096 23.7807 19.8109 23.8599C19.8121 23.9391 19.8286 24.0173 19.8593 24.09C19.8901 24.1627 19.9345 24.2284 19.99 24.2834C20.0455 24.3384 20.1111 24.3816 20.1829 24.4105L22.1255 25.202L22.5576 24.0834L20.615 23.2919Z"
        fill="#7AAEED"
      />
      <path
        d="M19.2845 26.7377C19.213 26.7086 19.1366 26.6942 19.0596 26.6954C18.9827 26.6966 18.9068 26.7133 18.8361 26.7447C18.7655 26.776 18.7016 26.8213 18.648 26.8781C18.5945 26.9348 18.5523 27.0018 18.5239 27.0752C18.4956 27.1487 18.4816 27.2272 18.4827 27.3062C18.4839 27.3852 18.5002 27.4632 18.5307 27.5357C18.5612 27.6083 18.6054 27.6739 18.6606 27.7289C18.7158 27.784 18.781 27.8273 18.8526 27.8564L20.7951 28.6479L21.2272 27.5292L19.2845 26.7377Z"
        fill="#7AAEED"
      />
      <path
        d="M16.6616 29.6561C16.5176 29.5994 16.3577 29.6033 16.2164 29.6669C16.0752 29.7305 15.9641 29.8488 15.9072 29.9961C15.8504 30.1433 15.8523 30.3077 15.9127 30.4534C15.9731 30.5992 16.087 30.7146 16.2297 30.7747L19.4642 32.0933L19.8962 30.9735L16.6616 29.6561Z"
        fill="#7AAEED"
      />
      <path
        d="M16.6236 33.6264C16.552 33.5969 16.4754 33.5821 16.3983 33.583C16.3212 33.584 16.245 33.6005 16.1741 33.6318C16.1032 33.6631 16.039 33.7084 15.9852 33.7652C15.9314 33.822 15.8891 33.8891 15.8606 33.9628C15.8322 34.0364 15.8182 34.1151 15.8194 34.1944C15.8207 34.2736 15.8371 34.3518 15.8679 34.4245C15.8986 34.4971 15.943 34.5629 15.9985 34.6178C16.0541 34.6728 16.1197 34.716 16.1915 34.7449L18.134 35.5364L18.566 34.4179L16.6236 33.6264Z"
        fill="#7AAEED"
      />
      <path
        d="M15.2927 37.0713C15.2212 37.0422 15.1448 37.0277 15.0678 37.0289C14.9908 37.03 14.9149 37.0467 14.8442 37.078C14.7735 37.1094 14.7095 37.1547 14.6559 37.2114C14.6023 37.2681 14.5601 37.3351 14.5316 37.4085C14.5032 37.482 14.4892 37.5605 14.4903 37.6395C14.4914 37.7186 14.5077 37.7966 14.5382 37.8692C14.5687 37.9418 14.6128 38.0075 14.668 38.0626C14.7232 38.1177 14.7884 38.161 14.8599 38.1902L16.8025 38.9817L17.2344 37.8632L15.2927 37.0713Z"
        fill="#7AAEED"
      />
    </svg>
  );
}

export default HealthGraphic;
