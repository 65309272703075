import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.css';
import darkIndicator from '../../resources/indicator-dots-dark.svg';
import lightIndicator from '../../resources/indicator-dots-light.svg';

export function IndicatorDots(props) {
  const elements = Array(props.numElements)
    .fill(lightIndicator)
    .fill(darkIndicator, props.indicator, props.indicator + 1);
  return (
    <div className="indicator-dots">
      {elements.map((item, index) => (
        <img src={item} key={index} alt="indicator dots"/>
      ))}
    </div>
  );
}

IndicatorDots.propTypes = {
  indicator: PropTypes.number.isRequired,
  numElements: PropTypes.number.isRequired,
};

IndicatorDots.defaults = {
  indicator: 0,
  numElements: 6,
};
