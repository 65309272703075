import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: undefined },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});
export const userActions = authSlice.actions;
export default authSlice.reducer;
