import React from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';
import { Pills } from '../Pills/Pills';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export function Card({
  id,
  index,
  header,
  address,
  distance,
  website,
  phone_string,
  phone,
  services,
  hours,
  active,
  classScroll,
  verifiedBy,
  resourceData
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentDay = (new Date().getDay() - 1) % 7;

  function handleClick(e) {
    e.stopPropagation();
  }

  let openTime, closeTime;
  if (hours && hours[currentDay]) {
    // Get the opening and closing hours for the current day
    const { open, close } = hours[currentDay];
    openTime = open;
    closeTime = close;
  }

  const formatPhoneNumber = (number) => {
    const cleaned = `${number}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };

  const minutesFromMidnight = (hours, minutes) => hours * 60 + minutes;

  const isOpenNow = (currentTime, openTimeInMinutes, closeTimeInMinutes) => {
    if (openTimeInMinutes < closeTimeInMinutes) {
      return currentTime >= openTimeInMinutes && currentTime < closeTimeInMinutes;
    }
    // Hours cross midnight
    return currentTime >= openTimeInMinutes || currentTime < closeTimeInMinutes;
  };

  const formatTime12Hour = (hours, minutes) => {
    const period = hours < 12 ? 'AM' : 'PM';
    const adjustedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${adjustedHours}:${formattedMinutes} ${period}`;
  };

  const getOrgStatus = (currentTime) => {
    if (!openTime || !closeTime) return;
    const openTimeInMinutes = minutesFromMidnight(openTime.hours, openTime.minutes);
    const closeTimeInMinutes = minutesFromMidnight(closeTime.hours, closeTime.minutes);

    if (!isOpenNow(currentTime, openTimeInMinutes, closeTimeInMinutes)) {
      return {
        variant: 'error',
        text: `${t('Closed • Opens at')} ${formatTime12Hour(openTime.hours, openTime.minutes)}`,
      };
    }

    const minutesUntilClosing =
      closeTimeInMinutes > currentTime
        ? // Same day
        closeTimeInMinutes - currentTime
        : // Next day
        1440 - currentTime + closeTimeInMinutes;

    if (minutesUntilClosing <= 60) {
      return {
        variant: 'warning',
        text: `${t('Closing Soon • Closes at')} ${formatTime12Hour(
          closeTime.hours,
          closeTime.minutes
        )}`,
      };
    }
    return {
      variant: 'success',
      text: `${t('Open Now • Closes at')} ${formatTime12Hour(closeTime.hours, closeTime.minutes)}`,
    };
  };

  const now = new Date();
  const currentTime = minutesFromMidnight(now.getHours(), now.getMinutes());
  const orgStatus = getOrgStatus(currentTime);

  return (
    <div
      className={`card ${active && 'card__active'} ${classScroll}`}
      onClick={() => navigate(`/resource/${id}`)}
    >
      <div className="card__left">
        <div className="card__header">
          {index}. {header ? header : 'Unknown'}
          {verifiedBy && (
            <CheckCircleOutlineIcon strokeWidth={1.05} stroke="currentColor" sx={{ marginLeft: '10px', width: '23px', height: '23px', color: '#233b5f' }} />
          )}
        </div>
        <div className="card__address">
          {resourceData?.address ? (
            <a
              href={`https://www.google.com/maps/place/${encodeURIComponent(`${address?.street}, ${address?.city}, ${address?.state}, ${address?.zip}`)}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <div>{address?.street}</div>
              <div>{`${address?.city}, ${address?.state} ${address?.zip}`}</div>
            </a>
          ) : (
            <a
              href={`https://www.google.com/maps/place/${encodeURIComponent(`${resourceData.address_string}`)}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <div>{resourceData.address_string}</div>
            </a>
          )}
          <div className="card__distance">{distance}</div>
        </div>
        <div className="card__website">
          <IonIcon name="earth" className="card__icon" />
          {website ? (
            <a
              className="card__website"
              href={
                website.startsWith('http://') || website.startsWith('https://')
                  ? website
                  : 'http://' + website
              }
              target="_blank"
              rel="noreferrer"
              onClick={handleClick}
            >
              {website}
            </a>
          ) : (
            'N/A'
          )}
        </div>
        {((resourceData?.phone?.areaCode && resourceData?.phone?.number) || resourceData?.phone_string?.trim() !== '') && (
          <div className="card__phone">
            <IonIcon name="call" className="card__icon" />
            <>
              {phone?.areaCode && phone?.number ? (
                <a href={`tel: +${phone.areaCode} ${phone.number}`} className="card__phone-link">
                  {`+${phone.areaCode} ${phone.number}`}
                </a>
              ) : resourceData?.phone_string && (
                <a href={`tel:${resourceData?.phone_string}`} className="card__phone-link">
                  {resourceData?.phone_string}
                </a>
              )}
            </>
          </div>
        )}
        {services.length > 0 && (
          <div className="card__services--wrapper">
            <div className="card__services">{t('Services')}</div>
            <div className="card__pills">
              <div className="card__pills-layout">
                {services.map((item, index) => (
                  <Pills key={index} variant="general" text={item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card__right">
        {orgStatus && (
          <span className="card__hours-pill">
            <Pills variant={orgStatus.variant} text={orgStatus.text} />
          </span>
        )}
      </div>
    </div>
  );
}

Card.defaultProps = {
  index: 1,
  header: 'Header',
  address: 'Address street, City State Zip',
  distance: 'Distance from your location',
  website: 'website.com',
  phone: undefined,
  services: ['Free lunch', 'Free dinner', 'Adult education'],
  hours: [
    {
      day: 'Tuesday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Wednesday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Thursday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Friday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Saturday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    { day: 'Sunday', openTime: null, closeTime: null },
    {
      day: 'Monday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
  ],
  active: true,
};

Card.propTypes = {
  index: PropTypes.number,
  header: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  distance: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  highlights: PropTypes.arrayOf(PropTypes.string),
  accessibility: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  hours:
    PropTypes.string ||
    PropTypes.arrayOf(
      PropTypes.shape({
        open: PropTypes.shape({
          hours: PropTypes.number,
          minutes: PropTypes.number,
        }),
        close: PropTypes.shape({
          hours: PropTypes.number,
          minutes: PropTypes.number,
        }),
      })
    ),
};
