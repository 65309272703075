import React from 'react';

function JobsGraphic() {
  return (
    <svg
      width="55"
      height="50"
      viewBox="0 0 55 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1678 10.833H36.5044L35.5223 5.55438C35.4386 5.09554 35.1952 4.68136 34.8355 4.3856C34.4758 4.08983 34.0231 3.93167 33.558 3.93928H21.183C20.7179 3.93167 20.2651 4.08983 19.9054 4.3856C19.5457 4.68136 19.3024 5.09554 19.2187 5.55438L18.2365 10.833H14.6321L15.8598 4.04761C16.0646 2.91213 16.6606 1.88485 17.5438 1.14508C18.4269 0.405309 19.5411 3.51795e-05 20.6919 0H34.108C35.2587 3.51795e-05 36.3729 0.405309 37.2561 1.14508C38.1393 1.88485 38.7353 2.91213 38.9401 4.04761L40.1678 10.833Z"
        fill="#3367B7"
      />
      <path
        d="M54.8999 12.2523V43.6754C54.8999 46.9891 52.2136 49.6754 48.8999 49.6754H34.0097H3.82847C2.78655 49.6754 1.7873 49.2604 1.05055 48.5216C0.313804 47.7829 -0.100098 46.7809 -0.100098 45.7361V12.2523C-0.100098 11.2075 0.313804 10.2055 1.05055 9.46678C1.7873 8.72802 2.78655 8.31299 3.82847 8.31299H50.9713C52.0133 8.31299 53.0125 8.72802 53.7492 9.46678C54.486 10.2055 54.8999 11.2075 54.8999 12.2523Z"
        fill="#689CEB"
      />
      <path
        d="M54.9999 31.9998C54.9999 30.5244 54.8628 38.0957 54.774 43.9287C54.7235 47.2419 52.0139 49.9256 48.7003 49.9257L3.86317 49.9267C2.83916 49.9267 2.01324 49.3259 1.28916 48.6337C0.565072 47.9415 0.014405 46.5586 0.014405 45.5797L0.0144043 42.9936C0.014405 43.6401 1.05244 44.7383 1.77652 45.4304C2.50061 46.1226 3.48268 46.5115 4.50668 46.5115H37.9397H40.1853C48.2497 46.5115 54.8334 40.0624 54.9999 31.9998Z"
        fill="#5082CD"
        fillOpacity="0.6"
      />
      <path
        d="M50.9713 8.31299H3.82847C1.65878 8.31299 -0.100098 10.0767 -0.100098 12.2523V23.0853C-0.100098 25.2609 1.65878 27.0246 3.82847 27.0246H50.9713C53.141 27.0246 54.8999 25.2609 54.8999 23.0853V12.2523C54.8999 10.0767 53.141 8.31299 50.9713 8.31299Z"
        fill="#8CB4F0"
      />
      <path
        d="M27.475 31.9625C26.6936 31.9625 25.9441 31.6513 25.3916 31.0972C24.839 30.5431 24.5286 29.7917 24.5286 29.0081V25.084C24.5286 24.5317 24.9763 24.084 25.5286 24.084H29.4214C29.9737 24.084 30.4214 24.5317 30.4214 25.084V29.0081C30.4214 29.7917 30.111 30.5431 29.5584 31.0972C29.0059 31.6513 28.2564 31.9625 27.475 31.9625Z"
        fill="#FFCB8D"
      />
    </svg>
  );
}

export default JobsGraphic;
