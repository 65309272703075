import { Outlet } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react';
import { Header } from '../Header/Header';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import i18n from '../../_locales/i18n';

const InitializeApp = () => {
  const [loading, setLoading] = useState(true);
  const getUserInfo = useGetUserInfo();

  useEffect(() => {
    const persistLanguageSelection = async () => {
      const language = localStorage.getItem('FRM_LOCALE');
      if (language) i18n.changeLanguage(language);
    };

    const fetchData = async () => {
      await getUserInfo();
      setLoading(false);
    };

    persistLanguageSelection();
    // Call the async function
    fetchData();
  }, []);

  return (
    <div>
      {loading
        ? <Fragment>
          <Header searchBar={false} />
          <Box align='center' sx={{
            'position': 'fixed',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)'
          }}>
            <CircularProgress size="80px" sx={{ color: "#0052cc" }} />
          </Box>
        </Fragment>
        : <Outlet />
      }
    </div>
  )
}

export default InitializeApp;