import React from 'react';
import PropTypes from 'prop-types';
import FoodGraphic from '../../resources/illustrations/FoodGraphic';
import ShelterGraphic from '../../resources/illustrations/ShelterGraphic';
import HealthGraphic from '../../resources/illustrations/HealthGraphic';
import FamilyGraphic from '../../resources/illustrations/FamilyGraphic';
import JobsGraphic from '../../resources/illustrations/JobsGraphic';
import LGBTGraphic from '../../resources/illustrations/LGBTGraphic';
import FinancialGraphic from '../../resources/illustrations/FinancialGraphic';
import DomesticGraphic from '../../resources/illustrations/DomesticGraphic';
import EducationGraphic from '../../resources/illustrations/EducationGraphic';

export function GraphicButton({ title, content, graphic, onClick }) {
    return (
        <div onClick={onClick} className="graphic-button">
            {graphic === 'food' && <FoodGraphic className="graphic-button__graphic" />}
            {graphic === 'family' && <FamilyGraphic className="graphic-button__graphic" />}
            {graphic === 'health' && <HealthGraphic className="graphic-button__graphic" />}
            {graphic === 'shelter' && <ShelterGraphic className="graphic-button__graphic" />}
            {graphic === 'lgbt' && <LGBTGraphic className="graphic-button__graphic" />}
            {graphic === 'jobs' && <JobsGraphic className="graphic-button__graphic" />}
            {graphic === 'financial' && <FinancialGraphic className="graphic-button__graphic" />}
            {graphic === 'domestic' && <DomesticGraphic className="graphic-button__graphic" />}
            {graphic === 'education' && <EducationGraphic className="graphic-button__graphic" />}
            <div className="graphic-button__body">
                <span className="graphic-button__title">{title}</span>
                <p className="graphic-button__paragraph">{content}</p>
            </div>
        </div>
    );
}

GraphicButton.propTypes = {
    /**
     * Image that accompanies the text for the button.
     */
    graphic: PropTypes.string,
    /**
     * The large text to display on button.
     */
    title: PropTypes.string,
    /**
     * The smaller text to display underneath the title.
     */
    content: PropTypes.string,
};

GraphicButton.defaultProps = {
    title: 'Food',
    content: 'Pantry for food stamps, free lunches for kids, etc.',
    graphic: 'food',
};
