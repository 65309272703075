import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Paper,
  TextField,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MicrosoftIcon from './microsoft-icon.png';
import GoogleIcon from './google-icon.png';
import { Button } from '../../components/Button/Button';
import { styled } from '@mui/material/styles';
import Alert from '../../components/Alert/Alert';
import { axiosPrivate } from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '36rem',
    fontSize: '1.8rem',
    border: '1px solid #dadde9',
    padding: '1.6rem',
    borderRadius: '1.2rem'
  },
}));

function OrDivider() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
      <Divider sx={{ flexGrow: 1 }} />
      <div style={{ fontSize: '1.6rem' }}>
        <span style={{ padding: '0rem 2rem', fontSize: '2rem' }}>or</span>
      </div>
      <Divider sx={{ flexGrow: 1 }} />
    </Box>
  );
}

function SignInOptionsPage({ handleNext, callbackIdP }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [errObject, setErrObject] = useState(undefined);
  /*
    const handleGoogleSignIn = async () => {
      try {
        const response = await axiosPrivate.get('/auth/signin/google');
        window.location.href = response.data.payload.redirectUrl;
      } catch (err) {
        setErrObject({title: 'Unable to sign you in', message: err.response.data.errMsg});
      }
      //
    };
  */
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setErrObject(undefined);
      const response = await axiosPrivate.get('/auth/signin/google');
      window.location.href = response.data.payload.redirectUrl;
    } catch (err) {
      if (err.response && err?.response?.data && err?.response?.data?.errMsg) {
        setErrObject({ title: `${t('Unable to sign you in')}`, message: err.response.data.errMsg });
      } else {
        // Handle cases where the structure is not as expected
        setErrObject({
          title: `${t('Unexpected Error')}`,
          message: `${t('An unexpected error occurred. Please try again later.')}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMicrosoftSignIn = async () => {
    // Handle sign-in with Microsoft 365.
    try {
      setLoading(true);
      setErrObject(undefined);
      const response = await axiosPrivate.get('/auth/signin/ms365');
      window.location.href = response?.data?.payload?.redirectUrl;
    } catch (err) {
      if (err.response && err?.response?.data && err?.response?.data?.errMsg) {
        setErrObject({ title: `${t('Unable to sign you in')}`, message: err.response.data.errMsg });
      } else {
        // Handle cases where the structure is not as expected
        setErrObject({
          title: `${t('Unexpected Error')}`,
          message: `${t('An unexpected error occurred. Please try again later.')}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleCallback = async () => {
    try {
      setLoading(true);

      const queryParamsGoogle = new URLSearchParams(window.location.search);
      const state = queryParamsGoogle.get('state');
      const code = queryParamsGoogle.get('code');
      //window.history.replaceState({}, document.title, '/auth/signin');

      const response = await axiosPrivate.post(`/auth/signin/google`, { state, code });
      window.location.href = '/dashboard';
    } catch (err) {
      setErrObject({ title: `${t('Unable to sign you in')}`, message: err?.response?.data?.errMsg });
    } finally {
      setLoading(false);
    }
  };

  const handleMicrosoftCallback = async () => {
    try {
      setLoading(true);

      const queryParamsGoogle = new URLSearchParams(window.location.search);
      const state = queryParamsGoogle.get('state');
      const code = queryParamsGoogle.get('code');
      window.history.replaceState({}, document.title, '/auth/signin');

      const response = await axiosPrivate.post(`/auth/signin/ms365`, { state, code });
      window.location.href = '/dashboard';
    } catch (err) {
      setErrObject({ title: `${t('Unable to sign you in')}`, message: err?.response?.data?.errMsg });
    } finally {
      setLoading(false);
    }
  };

  const handlesCallback = async () => {
    if (callbackIdP === 'google') {
      await handleGoogleCallback();
    }
    if (callbackIdP === 'ms365') {
      await handleMicrosoftCallback();
    }
  }

  const checkSignedIn = async () => {
    if (user?.roles?.includes('100001')) window.location.href = "/dashboard";
  };

  useEffect(() => {
    checkSignedIn();
    handlesCallback();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={2}
        sx={{
          margin: '4.8rem 0',
          padding: '32px',
          maxWidth: '500px',
          width: '100%',
          borderRadius: '1.2rem',
          boxSizing: 'border-box',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ fontSize: '2.8rem', fontWeight: 700, textAlign: 'center', marginBottom: '1.2rem' }}>{t('Sign In')}</div>
            <div style={{ fontSize: '1.8rem', textAlign: 'center', marginBottom: '1.2rem' }}>{t('to manage or add new resources.')}</div>
          </Grid>
          {(errObject && !loading) && (
            <Grid item xs={12} paddingBottom={3}>
              <Alert data={errObject} />
            </Grid>
          )}
          {(loading) ? (
            <Box sx={{
              'display': 'flex',
              'justifyContent': 'center',
              'alignItems': 'center',
              'marginY': '48px',
              'width': '100%'
            }}
            >
              <CircularProgress size="60px" sx={{ 'color': '#233b5f' }} />
              {/* <>{t('Signing you in')}</> */}
            </Box>
          ) : (
            <>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="secondaryGoogle"
                    onClick={handleGoogleSignIn}
                    sx={{
                      width: '100%',
                      height: '50px',
                      border: '1px solid #dfe1e5',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                    fullWidth
                    iconSrc={GoogleIcon}
                    label={`${t('Continue with')} Google`}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="secondaryMicrosoft"
                    onClick={handleMicrosoftSignIn}
                    sx={{
                      width: '100%',
                      height: '50px',
                      border: '1px solid #dfe1e5',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                    fullWidth
                    iconSrc={MicrosoftIcon} // Path to your Microsoft icon image
                    label={`${t('Continue with')} Microsoft 365`}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <center>
                  <OrDivider />
                </center>
              </Grid>
              <Grid item xs={12}>
                <div style={{ fontWeight: 700, fontSize: '1.8rem' }}>Use Business Email</div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    label=""
                    variant="outlined"
                    placeholder="name@example.org"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: '1.2rem',
                        fontSize: '1.8rem',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#00233D',
                          borderWidth: '2px',
                        },
                      },
                    }}
                  />
                </div>
              </Grid> */}

              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }} >
                <HtmlTooltip sx={{ fontSize: '1.8rem', }} title={t('Welcome to the FRM! There\'s no need to create a separate account. Please sign in with your company/org-provided account. An FRM profile will be established for you after initial sign in.')} arrow>
                  <span style={{ color: '#0052cc', minWidth: '1.8rem', fontWeight: '600', fontSize: '1.8rem', margin: '1.8rem 0rem' }}>{t('First time here?')}</span>
                </HtmlTooltip>
                {/* <Button variant="primary" onClick={handleNext} sx={{ width: '18rem' }} label="Next" /> */}
              </Grid>
            </>
          )
          }

        </Grid>
      </Paper>
    </div>
  );
}

export default SignInOptionsPage;


