import React from 'react';

function EducationGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="55"
      fill="none"
      viewBox="0 0 56 55"
    >
      <g clipPath="url(#clip0_573_1784)">
        <g clipPath="url(#clip1_573_1784)">
          <path
            fill="#A4B8D6"
            fillOpacity="0.39"
            d="M19.377 33.191h-8.998v6.693h8.998v-6.693z"
          />
          <path
            fill="#045BDE"
            d="M9.737 32.66a.266.266 0 000 .532H17.7c.909 0 1.645.737 1.645 1.646v3.4c0 .908-.736 1.645-1.645 1.645H9.72a.25.25 0 100 .5h37.73v-7.724H9.737z"
          />
          <path
            fill="#E5EAF3"
            d="M1.652 41.082h27.432c2.932 0 5.325 2.365 5.325 5.26v.367c0 2.896-2.36 5.261-5.29 5.261H1.651V41.082z"
          />
          <path
            fill="#74ABFF"
            d="M49.507 40.382H.337c-.203 0-.337.465-.337.665s.134.333.337.333L29 41.5c3.033 0 5.409 1.978 5.409 4.975 0 2.996-2.426 5.427-5.46 5.46H.5c-.202 0-.337.365-.337.565 0 .2.135.5.337.5h49.007C52.54 53 55 50.17 55 47.174v-1.331c0-2.997-2.46-5.428-5.493-5.461 0 .033 0 .033 0 0z"
          />
          <path
            fill="#A4B8D6"
            fillOpacity="0.58"
            d="M29.96 43.413H1.652v-.366H29.96c.1 0 .203.1.203.2s-.102.166-.203.166z"
          />
          <path
            fill="#A4B8D6"
            d="M16.884 38.284H10.38v-.366h6.538c.1 0 .202.1.202.2a.25.25 0 01-.235.166zM15.334 36.187H10.38v-.367h4.988c.1 0 .202.068.202.2-.034.067-.1.167-.235.167z"
            opacity="0.8"
          />
          <path
            fill="#A4B8D6"
            fillOpacity="0.58"
            d="M32.016 47.642H1.652v-.367h30.365c.1 0 .203.1.203.2-.002.1-.102.167-.204.167zM28.276 50.006H1.652v-.4h26.624c.1 0 .203.068.203.167-.002.133-.102.2-.203.233zM31.107 45.577H1.652v-.366h29.455c.1 0 .203.1.203.2-.035.066-.103.166-.203.166z"
          />
          <path
            fill="#F8E7D8"
            d="M27.227 32.056h-11.02v-4.029h11.02c.64 0 1.146.5 1.146 1.133v1.799c0 .598-.505 1.097-1.146 1.097z"
          />
          <path
            fill="#FDB62F"
            d="M48.7 27.429H15.27a.3.3 0 100 .598h11.964c.64 0 1.147.5 1.147 1.133v1.765a1.13 1.13 0 01-1.147 1.132H15.337c-.169.034-.303.167-.27.333 0 .133.135.266.27.266H48.7c.64 0 1.146-.5 1.146-1.133v-2.997c-.001-.532-.54-1.097-1.146-1.097z"
          />
          <path
            fill="#C5C9D0"
            fillOpacity="0.51"
            d="M14.426 23.266h17.963c1.044 0 1.92.832 1.92 1.897v.133a1.908 1.908 0 01-1.92 1.898H14.426v-3.928z"
          />
          <path
            fill="#110580"
            d="M45.127 23H13.65c-.068 0-.134.067-.134.133 0 .067.068.133.134.133h18.67c1.08 0 1.99.865 1.99 1.964a1.98 1.98 0 01-1.99 1.965H13.65c-.068 0-.134.067-.134.133 0 .067.068.133.134.133h31.476c1.078 0 1.989-.866 1.989-1.965v-.533c.001-1.097-.909-1.963-1.988-1.963z"
          />
          <path
            fill="#A4B8D6"
            fillOpacity="0.7"
            d="M30.332 24.365H14.426v-.167h15.906c.068 0 .1.034.1.1.002.033-.032.067-.1.067zM28.816 26.295h-14.39v-.166h14.39c.068 0 .1.034.1.1 0 .033-.033.066-.1.066zM32.118 25.332H14.426v-.2h17.692c.034 0 .1.033.1.099.002.034-.032.1-.1.1z"
          />
          <path
            fill="#FDB62F"
            d="M25.04 47.275h-4.414s-.708.234-1.315 1.365c-.742 1.399-.506 2.73-.977 3.763a3.67 3.67 0 01-1.45 1.664 6.29 6.29 0 001.585.034c.438-.1.842-.266 1.179-.533.1.234.134.466.168.7.034.233.034.499 0 .732 0 0 1.618-.366 2.258-1.299.91-1.266.134-2.83 1.347-4.795a7.464 7.464 0 011.62-1.63z"
          />
        </g>
        <path
          fill="#D7D7E6"
          d="M41.694 4.878l-.142-.036c-.322-.072-.643.107-.788.43l-.895 2.61c0 .036-.036.073-.036.107.322.25.716.43 1.11.5a2.17 2.17 0 001.073-.073V8.31l.215-2.753a.69.69 0 00-.537-.679z"
        />
        <path
          fill="#969696"
          d="M41.731.911c.073-.106.215-.142.322-.072.036.036.072.036.072.072.143.286.322.643.465.894.25.61.357 1.288.25 1.931-.142.5-.393.967-.679 1.358-.036.037-.072.073-.106.073a1.09 1.09 0 01-.68 0c-.43-.106-.464-.358-.464-.358-.142-.5-.215-1-.215-1.537.037-.5.215-1 .5-1.467.214-.287.393-.572.535-.894z"
        />
        <path
          fill="#06F"
          d="M38.226 18.36L39.8 8.17c0-.072.036-.106.036-.143.609.5 1.431.643 2.183.43 0 .073 0 .107-.036.18L39.55 18.647c-.107.358-.465.573-.823.464-.323-.142-.539-.43-.502-.751z"
        />
        <path
          fill="#DAE2ED"
          d="M37.264 0l-3.176.231 1.413 19.37 3.175-.232L37.264 0z"
        />
        <path
          fill="#045BDE"
          d="M35.39 2.766l-1.107.08.016.215 1.107-.08-.016-.215zM35.567 4.913l-1.107.08.016.215 1.106-.081-.015-.214zM35.712 7.094l-1.107.08.016.215 1.107-.08-.016-.215zM35.857 9.277l-1.107.08.016.215 1.106-.08-.015-.215zM36.033 11.42l-1.106.081.015.215 1.107-.081-.016-.215z"
        />
        <path
          fill="#73A7F5"
          d="M34.078 6.672l-2.043.357 2.677 15.287 2.043-.357-2.677-15.287z"
        />
        <path
          fill="#EBDBCD"
          d="M34.038 6.668L32 7.025l.322-2.931.43-.073 1.286 2.647z"
        />
        <path
          fill="#06F"
          d="M32.464 5.452l.288-.215.249.036.287-.142-.536-1.11-.43.073-.107 1.18.249.178z"
        />
        <path
          fill="#A4B8D6"
          d="M32.606 12.139h8.91c.214 0 .393.179.393.394v.036l-1.18 9.511c-.072.43-.562.92-.99.92h-5.616c-.43 0-.69-.453-.726-.92L32.25 12.57c-.036-.215.106-.394.321-.43h.034z"
        />
      </g>
      <defs>
        <clipPath id="clip0_573_1784">
          <path
            fill="#fff"
            d="M0 0H55V55H0z"
            transform="translate(.151)"
          />
        </clipPath>
        <clipPath id="clip1_573_1784">
          <path
            fill="#fff"
            d="M0 0H55V32H0z"
            transform="translate(0 23)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EducationGraphic;
