import React from 'react';

function DomesticGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="55"
      fill="none"
      viewBox="0 0 56 55"
    >
      <g clipPath="url(#clip0_573_2470)">
        <path
          fill="#518DE7"
          d="M24.105 6.892C-1.493 48.239.017 45.322.017 46.942a3.965 3.965 0 001.143 2.861 4.005 4.005 0 002.853 1.196h46.982a4.073 4.073 0 002.036-.576 4.045 4.045 0 001.468-1.517 4.02 4.02 0 00-.105-4.065L30.903 6.891a3.979 3.979 0 00-1.452-1.386 4.005 4.005 0 00-5.346 1.387z"
        />
        <path
          fill="#3370CC"
          d="M50.995 51H4.014a4.05 4.05 0 01-3.2-1.603 3.999 3.999 0 01-.653-3.504c.229.842.73 1.586 1.426 2.117.697.53 1.55.82 2.426.82h46.982a4.011 4.011 0 002.426-.82 3.974 3.974 0 001.426-2.117 3.992 3.992 0 01-.654 3.502A4.03 4.03 0 0150.995 51z"
        />
        <path
          fill="#C5DCFF"
          d="M4.799 46.643L27.504 9.961 50.21 46.643H4.8z"
        />
        <path
          fill="#518DE7"
          d="M29.685 20.189c-.702 15.43-.571 12.554-.728 16.056H26.05c-.157-3.472 0-.404-.729-16.056h4.363zM27.504 43.622a2.176 2.176 0 002.181-2.17c0-1.198-.976-2.17-2.181-2.17a2.176 2.176 0 00-2.182 2.17c0 1.198.977 2.17 2.182 2.17z"
        />
      </g>
      <defs>
        <clipPath id="clip0_573_2470">
          <path
            fill="#fff"
            d="M0 0H55V55H0z"
            transform="translate(.151)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DomesticGraphic;
