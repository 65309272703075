import React, { useState, useEffect } from 'react';
import filters from '../../components/FilterPanel/filters.json';
import tags from '../../components/FilterPanel/tags.json';
import { useDispatch, useSelector } from 'react-redux';
import { filterInputsActions } from '../../store/filterInput-slice';
import { useMediaQuery } from 'react-responsive';
import { currentPageActions } from '../../store/currentPage-slice';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {
  Footer,
  Header,
  Card,
  FilterPanel,
  ResourceMap,
  SearchResponsive,
  Pagination1,
  Button,
  FilterButton,
} from '../../components';
import Typography from '@mui/material/Typography';

import getDistance from 'geolib/es/getDistance';
import { useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { fetchAddress } from '../../store/enteredLocation-slice';
import axios from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export function Search() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [searchParams, setSearchParams] = useSearchParams();
  const cookies = new Cookies();
  const currentCoords = cookies.get('location');
  useEffect(() => {
    if (!enteredLocation && searchParams.has('address')) {
      const urlCoords = JSON.parse(searchParams.get('address'));
      dispatch(fetchAddress(urlCoords));
    }
  }, []);

  const filterInputs = useSelector((state) => state.filterInputs.filters);
  const currentPage = useSelector((state) => state.currentPage.page);
  const enteredTerm = useSelector((state) => state.enteredTerm.input);
  const enteredLocation = useSelector((state) => state.enteredLocation.input);
  const locationCoords = useSelector((state) => state.coords.coordinates);

  const [editingFilters, setEditingFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allPins, setAllPins] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState();
  const [active, setActive] = useState(-1);
  const [isSearching, setIsSearching] = useState(false);
  const [andTags, setAndTags] = useState(false);

  const filteredPins = allPins; //allPins.slice((currentPage - 1) * pageSize, currentPage * pageSize);


  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    console.log("pageSize: ", pageSize);
  };

  useEffect(() => {
    fetchResources(); // Log updated pageSize
  }, [pageSize]); // Run this effect whenever pageSize changes


  let total = [];
  Object.keys(filterInputs).forEach((key) => {
    if (typeof filterInputs[key] === 'boolean' && filterInputs[key]) {
      total.push(key);
    }
  });
  const toggleDrawer = () => {
    setEditingFilters(!editingFilters);
  };

  function activeScroll(index) {
    setActive(index);
  }

  useEffect(() => {
    const elem = document.getElementsByClassName(`resourcecard_${active}`)[0];
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [active]);

  const initialFilters = [];
  Object.keys(filters).forEach((key) => {
    filters[key].options.forEach((key) => {
      initialFilters.push(key.name);
    });
  });
  const setFilters = () => {
    const initialFilterState = initialFilters.reduce((state, prop) => {
      state[prop] = false;
      return state;
    }, {});
    initialFilterState.Distance = 'any';
    if (searchParams.has('tag')) {
      const arr = searchParams.get('tag').split(',');
      arr.map((item) => {
        const itemTrimmed = item.trim();
        return (initialFilterState[itemTrimmed] = true);
      });
    }
    if (searchParams.has('distance')) {
      initialFilterState['Distance'] = currentCoords ? searchParams.get('distance') : 'any';
    }
    if (searchParams.has('verifier')) {
      initialFilterState['Verifier'] = searchParams.get('verifier').split(',');;
    }
    dispatch(filterInputsActions.handlefilters(initialFilterState));
    setIsSearching(true);
  };
  useEffect(() => {
    setFilters();
  }, []);
  useEffect(() => {
    if (currentPage !== searchParams.get('page')) {
      setFilters();
    }
    setFilters();
  }, [searchParams.get('tag')]);

  // set URL for frontend
  const setURL = () => {
    const filtersString = Object.keys(filterInputs)
      .filter((key) => typeof filterInputs[key] === 'boolean' && filterInputs[key])
      .join(',');
    // const distanceString = Object.values(filterInputs).filter((key) => typeof key === 'string');
    const distanceString = filterInputs.Distance;
    const verifierString = filterInputs?.Verifier?.join(',');
    const params = {
      page: currentPage ? currentPage : 1,
      distance: distanceString,
      tag: filtersString,
      search: enteredTerm,
      address: JSON.stringify(locationCoords),
      verifier: verifierString,
    };

    if (!filtersString && !enteredTerm && !locationCoords) {
      delete params.tag;
      delete params.search;
      delete params.address;
    } else if (!filtersString && !enteredTerm) {
      delete params.tag;
      delete params.search;
    } else if (!filtersString && !locationCoords) {
      delete params.tag;
      delete params.address;
    } else if (!enteredTerm && !locationCoords) {
      delete params.search;
      delete params.address;
    } else if (!filtersString) {
      delete params.tag;
    } else if (!enteredTerm) {
      delete params.search;
    } else if (!locationCoords) {
      delete params.address;
    }
    if (!verifierString) {
      delete params.verifier;
    }
    if (location.state === 1) {
      setSearchParams(params, { replace: true });
    } else {
      setSearchParams(params);
    }
    setIsSearching(false);
  };

  useEffect(() => {
    if (isSearching) {
      setURL();
    }
  }, [isSearching]);

  const fetchResources = async (page = undefined) => {
    setIsLoading(true);
    setAllPins([]);
    let tagParams = [];
    let termParams = '';
    let addressParams = '';
    let distanceparams = searchParams.get('distance');
    let verifierparams = searchParams.get('verifier');
    if (searchParams.has('tag')) {
      const tagsArr = searchParams.get('tag').split(',');
      const urlTags = [];

      tagsArr.forEach((tag) => {
        let matchFound = false;

        Object.keys(tags).forEach((key) => {
          const tagArray = tags[key];
          if (
            tagArray.some((element) =>
              element.toLowerCase().includes(tag.toLowerCase())
            )
          ) {
            urlTags.push(key);
            matchFound = true;
          }
        });

        if (!matchFound) {
          urlTags.push(tag);
        }
      });

      tagParams = [...new Set(urlTags)];
    }

    if (searchParams.has('search')) {
      termParams = enteredTerm.replace(/ /g, '_');
    }
    if (searchParams.has('address')) {
      addressParams = JSON.parse(searchParams.get('address'));
    }

    const params = {
      page: page || currentPage,
      size: pageSize,
      search: termParams.toLowerCase(),
      longitude: addressParams.lng,
      latitude: addressParams.lat,
      tags: searchParams?.get('tag')?.toLowerCase(),
      distance: distanceparams,
      verifier: verifierparams
    }

    console.log(params);

    try {
      const response = await axios.get('/resource/search', {
        params: params
      })
      console.log(response?.data?.payload?.resources);
      setAllPins(response?.data?.payload?.resources || []);
      if (response?.data?.payload?.totalResults && params.size) {
        setTotalPages(Math.ceil(response.data.payload.totalResults / params.size));
      } else {
        setTotalPages(0);
      }
      setIsLoading(false);
      window.scrollTo(0, 0);
      return response;
    } catch (error) {
      console.error('Error fetching the data:', error);
    }
  }

  // set URl for backend
  useEffect(() => {
    fetchResources();
  }, [
    searchParams.get('tag'),
    searchParams.get('address'),
    searchParams.get('search'),
    searchParams.get('distance'),
    searchParams.get('verifier'),
    //andTags,
    // locationCoords,
  ]);

  useEffect(() => {
    if (currentPage !== searchParams.get('page')) {
      dispatch(currentPageActions.pageHandler(searchParams.get('page')));
    }
  }, [searchParams.get('page')]);

  // Functions and state information for resource cards
  const cards = filteredPins.map((result, index) => (
    <Card
      id={result.resourceId}
      key={index}
      index={pageSize * (currentPage - 1) + (index + 1)}
      header={result.name}
      address={result.address}
      distance={
        locationCoords && result.latitude && result.longitude
          ? `${(
            getDistance(
              {
                latitude: locationCoords.lat,
                longitude: locationCoords.lng,
              },
              { latitude: result.latitude, longitude: result.longitude }
            ) * 0.000621371
          ).toFixed(1)}  miles away`
          : ''
      }
      website={result.website}
      hours={result.hours}
      phone_string={result.phone_string}
      phone={result.phone}
      services={Array.isArray(result.tags) ? result.tags : []}
      verifiedBy={result.verifiedBy}
      active={result.id === active}
      classScroll={`resourcecard_${result.id}`}
      resourceData={result}
    />
  ));

  const pickPageSx = {
    height: "40px",
    width: "120px",
    // align: "center",
    // marginBottom: "40px",
    // margin: "2rem",
    borderColor: '#666E87',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid #666E87',
      borderRadius: '10px',
    },
  };

  const menuItemStyle = {
    color: '#00233D',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    // lineHeight: '26px',
    // flexDirection: 'column',
    // width: '100%',
    // marginBottom: "30px"
  };

  const pageOptions = [10, 20, 30];


  // End functions and state information for resource cards

  return (
    <>
      <Header landing={false} setIsSearching={setIsSearching} searchBar />
      {editingFilters && (
        <FilterPanel
          setIsLoading={setIsLoading}
          setEditingFilters={setEditingFilters}
          editingFilters={editingFilters}
          toggleDrawer={toggleDrawer}
          setIsSearching={setIsSearching}
          setAndTags={setAndTags}
          andTags={andTags}
        />
      )}
      {isMobile ? (
        isLoading && filteredPins.length === 0 ? (
          <div className="search-page__loading">{t('Please Wait Loading...')}</div>
        ) : (
          <SearchResponsive
            pins={allPins}
            cards={cards}
            setEditingFilters={setEditingFilters}
            totalPages={totalPages}
            setActive={setActive}
            locationCoords={locationCoords}
          />
        )
      ) : isLoading && filteredPins.length === 0 ? (
        <div className="search-page__loading">{t('Please Wait Loading...')}</div>
      ) : (
        <div className="search-page__main">
          <div className="search-page__results">
            <div className="search-page__filters">
              {!editingFilters && (
                <FilterButton
                  defaultValue={t('All Filters')}
                  filters={total.length}
                  selectedValue="selected"
                  setEditingFilters={setEditingFilters}
                />
              )}
              {!editingFilters &&
                initialFilters
                  .filter((item) => total.includes(item))
                  .map((item, idx) => (
                    <Button
                      key={idx}
                      onClick={() => {
                        dispatch(
                          filterInputsActions.handlefilters({
                            ...filterInputs,
                            [item]: !filterInputs[item],
                          })
                        );
                        dispatch(currentPageActions.pageHandler(1));

                        setIsSearching(true);
                      }}
                      label={t(item)}
                      variant={filterInputs[item] ? 'primary' : 'secondary'}
                    />
                  ))}
            </div>
            {cards.length > 0 ? (
              cards
            ) : (
              <div className="search-page__loading">
                <p className="p1search">
                  {t('Oops... we didn\'t find anything that matches this search.')}
                </p>
                <p>
                  {t('Try to search for something more general, change the filters or check for spelling mistakes.')}
                </p>
              </div>
            )}
            {/* <div style={{display: "flex", marginRight: "2rem"}} > */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Pagination1 totalPages={totalPages} refetch={fetchResources} setActive={setActive} />
              <div style={{ marginLeft: '10px', marginRight: "5px", marginTop: '-20px' }}>
                <Typography sx={{ fontSize: 14, fontFamily: 'Nunito' }} id="page-size-select-label">
                  Resources per page
                </Typography>
                <Select
                  sx={pickPageSx}
                  labelId="page-size-select-label"
                  id="page-size-select"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  {pageOptions.map((numPages) => (
                    <MenuItem value={numPages}><div style={menuItemStyle}>{numPages}</div></MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="search-page__map">
            <ResourceMap pins={allPins} activeScroll={activeScroll} resourceCounterOffset={(currentPage - 1) * pageSize} />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
