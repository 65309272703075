import React from 'react';
import PropTypes from 'prop-types';
import { Pills } from '../Pills/Pills';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';
export const CardResponsive = ({ header, address, distance, active, index, id }) => {
  const navigate = useNavigate();
  function handleClick(e) {
    e.stopPropagation();
  }

  return (
    <div className={`cardResponsive ${active && 'cardResponsive__active'}`}>
      <div className="cardResponsive__left">
        <div className="cardResponsive__header">{`${index + 1}. ${header}`}</div>
        <div className="cardResponsive__address">
          <a
            href={`https://maps.google.com?q=${encodeURIComponent(address)}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
          >
            <div>{address?.street}</div>
            <div>{address?.city}</div>
          </a>
          <div className="card__distance">{distance}</div>
        </div>
        <span className="cardResponsive__hours-pill">
          <Pills />
        </span>
        <div className="cardResponsive__button-wrapper--primary">
          <Button label="View" onClick={() => navigate(`/resource/${id}`)} />
        </div>
      </div>
    </div>
  );
};

CardResponsive.defaultProps = {
  header: 'Header',
  address: 'Address street, City State Zip',
  distance: 'Distance from your location',
  website: 'website.com',
  phone: 'Phone number',
  openTime: '7AM',
  closeTime: '5PM',
};

CardResponsive.propTypes = {
  header: PropTypes.string,
  address: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  distance: PropTypes.bool || PropTypes.string,
};
