import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// const queryParams = new URLSearchParams(window.location.search);
// const locationInput =
//     queryParams.has('address') && queryParams.get('address') !== 'Current Location'
//         ? queryParams.get('address')
//         : '';

export const fetchAddress = createAsyncThunk('enteredLocation/fetchAddress', async (coords) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coords.lat}&lon=${coords.lng}`;

    const response = await fetch(url);

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (data.length !== 0) {
        return data.display_name;
    } else {
        return '';
    }
});

const enteredLocationSlice = createSlice({
    name: 'enteredLocation',
    initialState: { input: '' },
    reducers: {
        handleLocationInput: (state, action) => {
            state.input = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAddress.fulfilled, (state, action) => {
            state.input = action.payload;
        });
    },
});
export const enteredLocationActions = enteredLocationSlice.actions;
export default enteredLocationSlice.reducer;
