import React, { useState } from 'react';
import PropTypes from 'prop-types';

export function FilterButton({ defaultValue, selectedValue, filters, setEditingFilters }) {
    const [selected, setSelected] = useState(false);

    return (
        <div
            onClick={() => {
                setSelected(!selected);
                setEditingFilters(true);
            }}
            className={selected ? 'filter-button filter-button--selected' : 'filter-button'}
        >
            {filters !== 0 && (
                <div className="filter-button__filtered">
                    <span>{filters}</span>
                </div>
            )}
            {selected ? selectedValue : defaultValue}
        </div>
    );
}

FilterButton.propTypes = {
    /**
     * Default state of the button when unselected.
     */
    defaultValue: PropTypes.string,
    /**
     * State of the button when selected.
     */
    selectedValue: PropTypes.string,
    /**
     * Displays a badge with the specified number.
     */
    filters: PropTypes.number,
};

FilterButton.defaultProps = {
    defaultValue: 'default',
    selectedValue: 'selected',
    filters: 0,
};
