import React from 'react';

function LGBTGraphic() {
  return (
    <svg
      width="55"
      height="38"
      viewBox="0 0 55 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55 29.5191V34.6583C55 35.0897 54.7286 35.4693 54.3136 35.587C51.372 36.4217 39.436 39.3248 27.5 35.3811C15.5096 31.4195 3.51928 34.0773 0.646746 34.8225C0.322048 34.9067 0 34.6617 0 34.3263V29.5191C0 29.5191 13.75 24.9761 27.5 29.5191C41.25 34.0622 55 29.5191 55 29.5191Z"
        fill="#CDB5EB"
      />
      <path
        d="M55 24.0191V29.8811C55 29.8811 41.25 34.4241 27.5 29.8811C13.75 25.3381 0 29.8811 0 29.8811V24.0191C0 24.0191 13.75 19.4761 27.5 24.0191C41.25 28.5622 55 24.0191 55 24.0191Z"
        fill="#7387ED"
      />
      <path
        d="M55 18.1508V24.0128C55 24.0128 41.25 28.5558 27.5 24.0128C13.75 19.4698 0 24.0128 0 24.0128V18.1508C0 18.1508 14.2892 14.1575 28.0392 18.7005C41.7892 23.2435 55 18.1508 55 18.1508Z"
        fill="#7FE47A"
      />
      <path
        d="M55 13.0199V18.8819C55 18.8819 41.7892 23.2435 28.0392 18.7004C14.2892 14.1574 0 19.801 0 19.801V13.0199C0 13.0199 13.75 9.20833 27.5 13.7514C41.25 18.2944 55 13.0199 55 13.0199Z"
        fill="#FFFF88"
      />
      <path
        d="M55 8.24959V14.1116C55 14.1116 41.25 18.2932 27.5 13.7502C13.75 9.20714 0 14.1116 0 14.1116V8.24959C0 8.24959 13.75 3.70657 27.5 8.24959C41.25 12.7926 55 8.24959 55 8.24959Z"
        fill="#FFB664"
      />
      <path
        d="M53.6724 2.40003C54.3315 2.22673 55 2.72125 55 3.40269V8.25018C55 8.25018 41.25 12.7932 27.5 8.25018C13.4804 4.4001 0 7.88109 0 7.88109V2.74189C0 2.31049 0.271356 1.93096 0.686376 1.81321C3.628 0.978566 15.564 -1.92455 27.5 2.01912C38.7034 5.72076 49.9069 3.39025 53.6724 2.40003Z"
        fill="#FF767A"
      />
      <path
        d="M24.1911 17.7098C24.802 18.1035 24.8022 18.9967 24.1916 19.3907L-1.49144e-05 35L0.0790272 2.1709L24.1911 17.7098Z"
        fill="#696969"
      />
      <path
        d="M19.9752 17.5649C20.5828 17.9472 20.6017 18.8262 20.011 19.2342L0.079119 33L0.0791187 5.04883L19.9752 17.5649Z"
        fill="#AA8161"
      />
      <path
        d="M15.7595 17.8629C16.3608 18.2458 16.3798 19.1171 15.7957 19.5258L0.0789706 30.5227L0.07897 7.87891L15.7595 17.8629Z"
        fill="#B2ECF5"
      />
      <path
        d="M11.5391 18.2986C12.1333 18.6921 12.1369 19.5631 11.546 19.9615L0.0790645 27.6928L0.0790644 10.71L11.5391 18.2986Z"
        fill="#FFD2E0"
      />
      <path
        d="M7.32566 18.6372C7.90885 19.0474 7.88766 19.9187 7.28522 20.3001L0.0789149 24.8619L0.0789137 13.54L7.32566 18.6372Z"
        fill="white"
      />
    </svg>
  );
}

export default LGBTGraphic;
