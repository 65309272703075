import React from 'react';

function FinancialGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <g clipPath="url(#clip0_573_2301)">
        <path
          fill="#77BA85"
          d="M26.622 38.126L40.638 9.378 26.76 2.612 12.745 31.36l13.877 6.766z"
        />
        <path
          fill="#62A770"
          d="M26.622 38.444a.315.315 0 01-.14-.032l-13.876-6.766a.319.319 0 01-.147-.426L26.475 2.472a.318.318 0 01.426-.147l13.877 6.766a.317.317 0 01.146.426L26.908 38.264a.32.32 0 01-.286.18zm-13.45-7.232L26.474 37.7 40.212 9.524 26.908 3.038 13.171 31.212z"
        />
        <path
          fill="#F6F9EA"
          d="M35.141 8.667l-4.435-2.162a3.48 3.48 0 01-4.653 1.603L15.114 30.543l10.692 5.213L36.744 13.32a3.48 3.48 0 01-1.603-4.653z"
        />
        <path
          fill="#62A770"
          d="M25.805 36.075a.318.318 0 01-.14-.032L14.975 30.83a.319.319 0 01-.147-.426L25.766 7.968a.318.318 0 01.426-.146 3.165 3.165 0 004.227-1.457.319.319 0 01.426-.146l4.436 2.162a.319.319 0 01.147.427 3.161 3.161 0 001.456 4.226.319.319 0 01.147.426L26.09 35.896a.318.318 0 01-.286.179zM15.54 30.396l10.119 4.934 10.664-21.874a3.8 3.8 0 01-1.597-4.636L30.84 6.926a3.805 3.805 0 01-4.635 1.597L15.54 30.396z"
        />
        <path
          fill="#62A770"
          d="M25.805 36.075a.317.317 0 01-.14-.032L14.975 30.83a.319.319 0 01-.147-.426L27.291 4.841a.319.319 0 01.426-.147l10.691 5.213a.319.319 0 01.147.426L26.092 35.896a.319.319 0 01-.287.18zM15.54 30.397l10.119 4.933 12.184-24.99-10.119-4.933-12.184 24.99z"
        />
        <path
          fill="#77BA85"
          d="M27.752 21.772a3.645 3.645 0 100-7.29 3.645 3.645 0 000 7.29z"
        />
        <path
          fill="#589B66"
          d="M27.745 22.09a3.964 3.964 0 113.57-2.227 3.94 3.94 0 01-3.57 2.226zm.01-7.291a3.329 3.329 0 10-.015 6.657 3.329 3.329 0 00.015-6.657z"
        />
        <path
          fill="#97CD78"
          d="M26.334 39.515l24.732-20.278L41.278 7.3 16.547 27.577l9.788 11.938z"
        />
        <path
          fill="#75AE53"
          d="M26.334 39.835a.319.319 0 01-.247-.117L16.3 27.779a.316.316 0 01-.035-.352.32.32 0 01.08-.096L41.076 7.054a.319.319 0 01.448.044l9.788 11.939c.027.032.047.07.059.11.012.04.016.082.012.123a.319.319 0 01-.115.215L26.536 39.763a.317.317 0 01-.202.072zm-9.34-12.213l9.384 11.446 24.24-19.874-9.384-11.445-24.24 19.873z"
        />
        <path
          fill="#F6F9EA"
          d="M46.367 16.298l-3.129-3.816a3.48 3.48 0 01-4.897-.485L19.039 27.822l7.541 9.198 19.302-15.825a3.48 3.48 0 01.485-4.897z"
        />
        <path
          fill="#75AE53"
          d="M26.581 37.34a.319.319 0 01-.247-.117l-7.541-9.198a.318.318 0 01.044-.449L38.14 11.751a.316.316 0 01.352-.035c.037.02.07.047.096.08a3.162 3.162 0 004.45.44.318.318 0 01.448.044l3.129 3.816a.319.319 0 01-.045.449 3.165 3.165 0 00-.44 4.449.318.318 0 01-.044.448L26.782 37.267a.319.319 0 01-.202.072zm-7.093-9.473l7.138 8.705 18.819-15.43a3.804 3.804 0 01.483-4.878l-2.742-3.344a3.8 3.8 0 01-4.879-.482L19.488 27.867z"
        />
        <path
          fill="#75AE53"
          d="M26.581 37.34a.319.319 0 01-.247-.117l-7.541-9.198a.32.32 0 01.044-.449L40.83 9.545a.32.32 0 01.449.044l7.541 9.199a.32.32 0 01-.044.448L26.783 37.267a.32.32 0 01-.202.073zm-7.093-9.473l7.138 8.706 21.5-17.628-7.138-8.706-21.5 17.628z"
        />
        <path
          fill="#97CD78"
          d="M35.705 25.457a3.645 3.645 0 100-7.289 3.645 3.645 0 000 7.29z"
        />
        <path
          fill="#75AE53"
          d="M35.713 25.777a3.95 3.95 0 11-.006-7.9 3.95 3.95 0 01.006 7.9zm-.01-7.288a3.324 3.324 0 10.005 6.648 3.324 3.324 0 00-.006-6.648z"
        />
        <path
          fill="#4884DE"
          d="M31.09 17H6.91C3.092 17 0 19.867 0 23.404v25.192C0 52.133 3.093 55 6.91 55h24.18c3.817 0 6.91-2.867 6.91-6.404V23.405C38 19.866 34.907 17 31.09 17z"
        />
        <path
          fill="#A1C1F0"
          d="M31.09 17H6.91C3.092 17 0 19.867 0 23.404v25.192C0 52.133 3.093 55 6.91 55h24.18c3.817 0 6.91-2.867 6.91-6.404V23.405C38 19.866 34.907 17 31.09 17z"
        />
        <rect
          width="30.5"
          height="29.5"
          x="3.75"
          y="20.75"
          stroke="#417BD2"
          strokeWidth="1.5"
          opacity="0.51"
          rx="3.25"
        />
        <path
          fill="#4884DE"
          fillOpacity="0.8"
          d="M31.09 53.656H6.91a7.045 7.045 0 01-4.886-1.95A6.534 6.534 0 010 47v1.344c0 1.765.728 3.458 2.024 4.707A7.045 7.045 0 006.909 55h24.182c1.832 0 3.59-.701 4.885-1.95A6.534 6.534 0 0038 48.345V47a6.534 6.534 0 01-2.024 4.706 7.045 7.045 0 01-4.885 1.95z"
        />
        <path
          fill="#518DE7"
          d="M22.8 14h-6.6c-1.215 0-2.2.867-2.2 1.937v13.126c0 1.07.985 1.937 2.2 1.937h6.6c1.215 0 2.2-.867 2.2-1.937V15.937c0-1.07-.985-1.937-2.2-1.937z"
        />
        <path
          fill="#366EC0"
          d="M22.8 31h-6.6a2.283 2.283 0 01-1.556-.6c-.412-.385-.644-.856-.644-1.4v.95c0 .543.232 1.065.644 1.45.413.384.973.6 1.556.6h6.6c.584 0 1.143-.216 1.556-.6.412-.385.644-.907.644-1.45V29c0 .544-.232 1.015-.644 1.4-.413.384-.973.6-1.556.6z"
        />
        <path
          fill="#0B52BD"
          d="M19.346 28.007a2.43 2.43 0 100-4.86 2.43 2.43 0 000 4.86z"
        />
        <path
          fill="#FDB740"
          d="M52.99 52.846h-7.438a.772.772 0 00-.772.772v.515c0 .427.346.772.772.772h7.438a.772.772 0 00.772-.772v-.515a.772.772 0 00-.772-.772z"
        />
        <path
          fill="#D1810E"
          d="M52.947 54.84h-7.438a.773.773 0 01-.772-.773v-.3a.772.772 0 00.772.771h7.438a.772.772 0 00.772-.771v.3a.772.772 0 01-.772.772z"
        />
        <path
          fill="#FDB740"
          d="M54.228 50.787H46.79a.772.772 0 00-.771.772v.516c0 .426.345.772.771.772h7.438a.772.772 0 00.772-.772v-.516a.772.772 0 00-.772-.772z"
        />
        <path
          fill="#D1810E"
          d="M54.152 52.794h-7.438a.773.773 0 01-.772-.772v-.301a.772.772 0 00.772.772h7.438a.773.773 0 00.772-.772v.3a.772.772 0 01-.772.773z"
        />
        <path
          fill="#FDB740"
          d="M52.99 48.727h-7.438a.772.772 0 00-.772.772v.516c0 .426.346.772.772.772h7.438a.772.772 0 00.772-.772V49.5a.772.772 0 00-.772-.772z"
        />
        <path
          fill="#D1810E"
          d="M52.947 50.85h-7.438a.771.771 0 01-.772-.772v-.3a.772.772 0 00.772.771h7.438a.772.772 0 00.772-.772v.301a.772.772 0 01-.772.772z"
        />
        <path
          fill="#FDB740"
          d="M43.069 47.822l-5.08 5.432a.772.772 0 00.036 1.09l.376.353c.312.291.8.275 1.091-.037l5.081-5.431a.772.772 0 00-.036-1.091l-.377-.353a.772.772 0 00-1.091.037z"
        />
        <path
          fill="#D1810E"
          d="M44.558 49.23l-5.081 5.432a.77.77 0 01-1.091.037l-.22-.206a.772.772 0 001.091-.037l5.08-5.431a.772.772 0 00-.036-1.091l.22.205a.772.772 0 01.037 1.091z"
        />
      </g>
      <defs>
        <clipPath id="clip0_573_2301">
          <path fill="#fff" d="M0 0H55V55H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FinancialGraphic;
