import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Footer, Header, Pills, Table } from '../../components';
import { Tooltip, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import axios from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export function Resource() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [cardData, setCardData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [locationAllowed, setLocationAllowed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCardData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/resource/approved/${id}`);
        const data = await response?.data;

        if (data?.errCode === "0" && data?.payload) {
          const resourceData = JSON.parse(data.payload.resourceData);
          setCardData(resourceData);
        } else {
          console.error('Error fetching the resource:', data);
          // handle error based on errCode or lack of payload
        }
      } catch (error) {
        console.error('Error fetching the resource id:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // if (locationAllowed) {
    //   fetchCardData();
    // }

    fetchCardData();
  }, [id, locationAllowed]);

  const renderVerificationCheckmark = (verfierName) => {
    return (
      <Tooltip arrow title={<span style={{ fontSize: '14px', lineHeight: '1.5' }}>{`${t('Verified and brought to you by')} ${verfierName}`}</span>} placement="top">
        <span style={{
          display: 'flex',
          'marginLeft': '20px',
          padding: '8px 16px',
          'align-items': 'center',
          gap: '8px',
          'border-radius': '28px',
          background: '#BFF6B6'
        }}>
          <svg style={{
            width: '16px',
            height: '16px'
          }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M2.5 7L6.5 11L13.5 4" stroke="#29660D" stroke-width="2" />
          </svg>
          <span style={{
            color: '#29660D',
            'text-align': 'center',
            'font-family': 'Nunito',
            'font-size': '16px',
            'font-style': 'normal',
            'font-weight': 700,
            'line-height': '24px', /* 150% */
          }}>{t('Verified')}</span>
        </span>
      </Tooltip >
    );
  };

  // Helper function to format hours
  const formatHours = (hours) => {
    if (!hours) return;
    try {
      const hoursObj = JSON.parse(hours);
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      // Map each day to its corresponding hours string
      const parsed = daysOfWeek.map(day => {
        const dayHours = hoursObj[day.toLowerCase()];
        if (!dayHours || dayHours.length === 0) {
          return { day, hoursString: 'Closed' };
        } else if (dayHours.length === 1 && dayHours[0][0] === "00:00" && dayHours[0][1] === "23:59") {
          return { day, hoursString: 'Open 24 hours' };
        } else {
          const hoursString = dayHours.map(slot => `${slot[0]} - ${slot[1]}`).join(', ');
          return { day, hoursString };
        }
      });
      console.log(parsed);
      //return parsed;
    } catch (error) {
      console.error('Error parsing hours:', error);
      return 'Closed';
    }
  };

  const formatTags = (tags) => {
    if (!tags) return [];
    return tags.map(str => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    });
  };

  return (
    <div className="resource-page">
      <Header landing={false} />
      {isLoading ? (
        <div className="resource-page__loading">{t('Please Wait Loading...')}</div>
      ) : cardData ? (
        <div className="resource-page__content">
          <div className="resource-page__left">
            <Button
              variant="secondary"
              type="button"
              size="medium"
              label="Back to search results"
              icon="arrow-back-outline"
              onClick={() => navigate(-1)}
            />
            <div className="resource-page__info">
              <div className="resource-page__heading">
                {cardData.name ? cardData.name : <p>{t('Resource.Singular')} {t('Information')}</p>}
                {cardData.verifierName && renderVerificationCheckmark(cardData.verifierName)}
              </div>
              <div className="resource-page__description">
                {cardData.description ? cardData.description : <p>N/A</p>}
              </div>

              <div className="resource-page__services--wrapper">
                <div className="resource-page__heading-secondary">{t('Services')}</div>
                <div className="resource-page__pills">
                  {formatTags(cardData.tags).map((tag, index) => (
                    <Pills variant="general" text={tag} key={index} />
                  ))}
                </div>
              </div>

              {cardData.highlights && (
                <div className="resource-page__highlights--wrapper">
                  <div className="resource-page__heading-secondary">{t('Highlights')}</div>
                  <div className="resource-page__pills">
                    {/* Assuming 'highlights' is an array of strings */}
                    {cardData.highlights && cardData.highlights.map((highlight, index) => (
                      <Pills variant="general" text={highlight} key={index} />
                    ))}
                  </div>
                </div>
              )}

              {cardData.accessibility && (
                <div className="resource-page__accessibility--wrapper">
                  <div className="resource-page__heading-secondary">{t('Accessibility')}</div>
                  <div className="resource-page__pills">
                    {/* Assuming 'accessibility' is an array of strings */}
                    {cardData.accessibility && cardData.accessibility.map((accessibilityItem, index) => (
                      <Pills variant="general" text={accessibilityItem} key={index} />
                    ))}
                  </div>
                </div>
              )}

            </div>
          </div>
          <div className="resource-page__table">
            {cardData && (
              <Table
                website={cardData.website}
                email={cardData.email}
                phone={
                  (cardData?.phone?.areaCode && cardData?.phone?.number) ?
                    cardData?.phone?.areaCode === '1' ?
                      `+${cardData?.phone?.areaCode} (${cardData?.phone?.number.substring(0, 3)})${cardData?.phone?.number.substring(3, 6)}-${cardData?.phone?.number.substring(6)}`
                      : `+${cardData?.phone?.areaCode} ${cardData?.phone?.number}`
                    : cardData.phone_string
                }
                addressObj={cardData.address}
                addressString={cardData.address_string}
                lat={cardData.latitude}
                lng={cardData.longitude}
                hours={formatHours(cardData.hours)}
                resourceData={cardData}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="resource-page__loading">{t('No such resource found')}</div>
      )}
      <Footer />
    </div>
  );
}
