import React from 'react';

function FamilyGraphic() {
  return (
    <svg
      width="55"
      height="50"
      viewBox="0 0 55 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_624_3812)">
        <path
          d="M28.3657 36.9248C26.9982 36.9248 25.6867 36.3816 24.7197 35.4146C23.7527 34.4476 23.2095 33.1361 23.2095 31.7686V30.0498C23.2095 28.6823 23.7527 27.3708 24.7197 26.4038C25.6867 25.4368 26.9982 24.8936 28.3657 24.8936C29.7332 24.8936 31.0448 25.4368 32.0117 26.4038C32.9787 27.3708 33.522 28.6823 33.522 30.0498V31.7686C33.522 33.1361 32.9787 34.4476 32.0117 35.4146C31.0448 36.3816 29.7332 36.9248 28.3657 36.9248Z"
          fill="#2079FF"
        />
        <path
          d="M45.5532 38.6436C44.4136 38.6436 43.3207 38.1908 42.5149 37.385C41.7091 36.5792 41.2563 35.4863 41.2563 34.3467V32.6279C41.2563 31.4883 41.7091 30.3954 42.5149 29.5896C43.3207 28.7838 44.4136 28.3311 45.5532 28.3311C46.6928 28.3311 47.7858 28.7838 48.5916 29.5896C49.3974 30.3954 49.8501 31.4883 49.8501 32.6279V34.3467C49.8501 35.4863 49.3974 36.5792 48.5916 37.385C47.7858 38.1908 46.6928 38.6436 45.5532 38.6436Z"
          fill="#86B7FF"
        />
        <path
          d="M11.1782 38.6436C10.0386 38.6436 8.94569 38.1908 8.13987 37.385C7.33405 36.5792 6.88135 35.4863 6.88135 34.3467V32.6279C6.88135 31.4883 7.33405 30.3954 8.13987 29.5896C8.94569 28.7838 10.0386 28.3311 11.1782 28.3311C12.3178 28.3311 13.4108 28.7838 14.2166 29.5896C15.0224 30.3954 15.4751 31.4883 15.4751 32.6279V34.3467C15.4751 35.4863 15.0224 36.5792 14.2166 37.385C13.4108 38.1908 12.3178 38.6436 11.1782 38.6436Z"
          fill="#86B7FF"
        />
        <path
          opacity="0.7"
          d="M33.522 30.0498V31.7686C33.522 33.1361 32.9787 34.4476 32.0117 35.4146C31.0448 36.3816 29.7332 36.9248 28.3657 36.9248C26.9982 36.9248 25.6867 36.3816 24.7197 35.4146C23.7527 34.4476 23.2095 33.1361 23.2095 31.7686V30.0498C23.2095 31.4173 23.7527 32.7288 24.7197 33.6958C25.6867 34.6628 26.9982 35.2061 28.3657 35.2061C29.7332 35.2061 31.0448 34.6628 32.0117 33.6958C32.9787 32.7288 33.522 31.4173 33.522 30.0498Z"
          fill="#0052CE"
        />
        <path
          opacity="0.5"
          d="M49.8501 32.6279V34.3467C49.8501 35.4863 49.3974 36.5792 48.5916 37.385C47.7858 38.1908 46.6928 38.6436 45.5532 38.6436C44.4136 38.6436 43.3207 38.1908 42.5149 37.385C41.7091 36.5792 41.2563 35.4863 41.2563 34.3467V32.6279C41.2563 33.7675 41.7091 34.8605 42.5149 35.6663C43.3207 36.4721 44.4136 36.9248 45.5532 36.9248C46.6928 36.9248 47.7858 36.4721 48.5916 35.6663C49.3974 34.8605 49.8501 33.7675 49.8501 32.6279Z"
          fill="#0B52BD"
        />
        <path
          opacity="0.5"
          d="M15.4751 32.6279V34.3467C15.4751 35.4863 15.0224 36.5792 14.2166 37.385C13.4108 38.1908 12.3178 38.6436 11.1782 38.6436C10.0386 38.6436 8.94569 38.1908 8.13987 37.385C7.33405 36.5792 6.88135 35.4863 6.88135 34.3467V32.6279C6.88135 33.7675 7.33405 34.8605 8.13987 35.6663C8.94569 36.4721 10.0386 36.9248 11.1782 36.9248C12.3178 36.9248 13.4108 36.4721 14.2166 35.6663C15.0224 34.8605 15.4751 33.7675 15.4751 32.6279Z"
          fill="#0052CE"
        />
        <path
          d="M54.7162 48.5853C54.8643 49.2131 54.388 49.8149 53.7429 49.8149H2.98215C2.33906 49.8149 1.86315 49.2167 2.00776 48.5901L2.75635 45.3462C3.13316 43.7517 3.95487 42.297 5.12592 41.1511C6.29696 40.0053 7.76923 39.2154 9.37151 38.8733C10.9738 38.5313 12.6403 38.6511 14.1771 39.2188C15.714 39.7866 17.0581 40.779 18.0532 42.0806C18.5221 42.7048 18.9037 43.39 19.1876 44.1173C37.5524 44.1173 37.5095 44.1946 37.5095 44.1173C40.6548 36.2454 52.1188 37.1564 53.9579 45.372L54.7162 48.5853Z"
          fill="#86B7FF"
        />
        <path
          d="M28.5 1.89274C27.6495 0.998995 26.5599 0.385961 25.3699 0.131638C24.18 -0.122685 22.9434 -0.00679839 21.8176 0.464552C20.6918 0.935902 19.7277 1.74141 19.0479 2.77857C18.3681 3.81574 18.0033 5.03767 18 6.2889C18 11.8055 26.0358 16.6327 28.0476 17.7547C28.3297 17.9121 28.6703 17.9121 28.9524 17.7547C30.9642 16.6327 39 11.8055 39 6.2889C38.9967 5.03767 38.6319 3.81574 37.9521 2.77857C37.2723 1.74141 36.3082 0.935902 35.1824 0.464552C34.0566 -0.00679839 32.82 -0.122685 31.6301 0.131638C30.4401 0.385961 29.3505 0.998995 28.5 1.89274Z"
          fill="#FFBE71"
        />
        <path
          d="M28.9378 17.779C28.6615 17.9208 28.3348 17.9206 28.0586 17.7784C23.0971 15.2237 16.6374 10.12 18.253 5C21.055 14.1277 35.8885 14.266 38.7432 5C40.3756 10.1362 33.885 15.2398 28.9378 17.779Z"
          fill="#FC9658"
        />
        <path
          d="M40.397 49.8154H16.3345C17.3228 44.8654 17.4688 43.6107 18.1048 42.0811C18.5755 42.701 18.9573 43.3836 19.2392 44.1092C19.2392 44.1951 19.0157 44.1092 37.561 44.1092C37.842 43.3867 38.2177 42.7047 38.6782 42.0811C39.2282 43.3873 39.254 43.9029 40.397 49.8154Z"
          fill="#4985DE"
        />
        <path
          d="M18.0532 49.8149L19.1103 44.5126C19.5523 42.3848 20.7141 40.4743 22.3998 39.1028C24.0856 37.7312 26.1925 36.9824 28.3657 36.9824C30.539 36.9824 32.6458 37.7312 34.3316 39.1028C36.0174 40.4743 37.1791 42.3848 37.6212 44.5126L38.6782 49.8149H18.0532Z"
          fill="#2079FF"
        />
        <path
          opacity="0.5"
          d="M54.7248 48.5913C54.8689 49.2177 54.393 49.8154 53.7502 49.8154H2.9812C2.33842 49.8154 1.86257 49.2177 2.00665 48.5913L2.12041 48.0967H54.611L54.7248 48.5913Z"
          fill="#0052CE"
        />
      </g>
      <defs>
        <clipPath id="clip0_624_3812">
          <rect width="55" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FamilyGraphic;
