import React from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';

export function Button(
  { variant, size, label, type, iconSrc, onClick },
  props
) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={['button', `button--${size}`, `button--${variant}`].join(' ')}
      {...props}
    >
      {iconSrc && (
        <img
          src={iconSrc}
          alt=""
          style={{
            marginRight: '8px',
            verticalAlign: 'middle',
            width: '24px',
            height: '24px',
          }}
        />
      )}
      {label}
    </button>
  );
}

Button.propTypes = {
  /**
   * Style of button to display.
   */
  variant: PropTypes.string.isRequired,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Button icon source file
   */
  iconSrc: PropTypes.string,
  /**
   * The type of button you want
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  iconSrc: '',
  onClick: undefined,
  variant: 'primary',
};
