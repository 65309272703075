import React from 'react';

function FoodGraphic() {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0365 11.3048C33.3268 10.0862 32.5951 8.84329 32.2334 7.48446C31.8716 6.12564 31.9045 4.59426 32.6554 3.39181C33.4062 2.18936 34.9655 1.43177 36.3248 1.87123C37.9991 2.41044 38.7089 4.44598 38.4814 6.16608C38.254 7.88618 37.3524 9.43373 36.7934 11.0756C36.6098 11.6122 36.4864 12.2727 36.8756 12.6987C37.2894 13.1408 38.0293 13.0222 38.5938 12.8065C40.2435 12.1568 41.6055 10.806 43.3566 10.4744C44.0006 10.3531 44.7679 10.4232 45.1625 10.9408C45.3379 11.2024 45.4365 11.507 45.4557 11.8198C45.6119 13.556 44.7597 15.2303 43.2552 16.1497C42.2248 16.759 40.9807 16.9612 39.9777 17.6109C39.753 17.7565 39.5228 17.9533 39.4817 18.2176C39.4406 18.4818 39.6242 18.7325 39.8105 18.9293C40.4052 19.5629 41.1835 20.005 41.7644 20.644C42.3481 21.2857 42.7016 22.2671 42.244 23.0031C41.7863 23.7391 40.7368 23.8685 39.8845 23.6771C39.0323 23.4857 38.2348 23.0543 37.3743 22.9411C36.5138 22.8278 35.4643 23.181 35.2286 24.0087C34.971 24.9173 35.826 25.7774 36.7139 26.1332C37.6018 26.4891 38.6102 26.5754 39.3967 27.1065C40.6272 27.945 40.9341 29.6031 40.0818 30.8136C39.6598 31.4122 39.0103 31.8139 38.2786 31.9271C37.4922 32.0484 36.7002 31.8247 35.9411 31.5874C31.9895 30.3499 28.194 28.6783 24.6233 26.6024C24.1273 26.3139 23.634 26.0092 23.2668 25.5671C22.2419 24.308 22.6886 22.472 23.1819 20.9352C23.4203 20.1965 23.7436 19.3634 24.4863 19.0992C25.4756 18.746 26.432 19.6195 27.413 19.9916C28.2379 20.2989 29.1559 20.2558 29.9479 19.8702C30.4083 19.6573 30.7837 19.296 31.0084 18.8457C31.3838 18.0046 30.992 17.007 30.3918 16.3006C29.7917 15.5943 28.997 15.0712 28.3776 14.381C27.6322 13.5506 27.1527 12.4129 27.3719 11.3237C27.5911 10.2318 28.6517 9.29083 29.7725 9.43373C31.3866 9.63593 32.1703 11.7065 33.757 12.0624C34.3435 11.9438 34.0365 11.3048 34.0365 11.3048Z"
        fill="#4C7378"
      />
      <path
        d="M45.6723 7.0584C45.76 5.99076 45.7052 3.59394 44.1295 3.50228C42.4551 3.40522 42.9456 5.891 43.1402 6.79149C43.4088 8.01821 43.828 9.24223 43.9404 10.4851C43.7349 10.9003 43.5759 11.3344 43.4663 11.7846C43.4416 11.968 43.5759 12.1378 43.7623 12.1594C43.7951 12.1648 43.8308 12.1621 43.8637 12.1567C43.8472 12.2403 43.828 12.3185 43.8061 12.4128C43.7349 12.6824 44.1432 12.7363 44.2281 12.491C44.3597 12.0974 44.4446 11.693 44.483 11.2805C44.7187 10.7386 44.9242 10.1859 45.0996 9.62237C45.3928 8.79468 45.5846 7.93193 45.6723 7.0584Z"
        fill="#66A896"
      />
      <path
        d="M51.4491 7.68645C50.7557 6.484 49.0951 6.79944 48.0126 7.22003C46.9301 7.64061 45.7134 8.43596 45.3352 9.56292C45.0037 9.95385 44.7762 10.423 44.6748 10.9217C44.6008 11.283 45.1955 11.5014 45.3188 11.132C45.5764 10.3798 46.0697 9.70042 46.8123 9.34723C47.5988 8.98326 48.473 9.09919 49.3061 9.1639C49.8953 9.20704 50.764 9.35802 51.2874 8.98865C51.7203 8.69208 51.6847 8.09625 51.4491 7.68645Z"
        fill="#4C7378"
      />
      <path
        d="M48.791 0.997617C48.0511 0.938303 47.3989 1.94933 47.0618 2.45889C46.5905 3.19222 46.2205 3.97948 45.9574 4.80718C45.6368 5.7562 45.4121 6.72678 45.1518 7.68928C44.8832 8.68144 44.5023 9.63855 44.2008 10.6118C44.0748 10.868 43.957 11.1241 43.8556 11.3856C43.7542 11.6498 44.1077 11.9383 44.3022 11.6768C45.0038 10.7439 45.508 9.6682 46.067 8.64909C46.6562 7.57335 47.2427 6.50031 47.8319 5.42727C48.1333 4.88806 50.6517 1.1459 48.791 0.997617Z"
        fill="#57848A"
      />
      <path
        d="M46.7931 15.176C47.6618 13.7417 46.6753 11.868 45.3709 10.7922C44.7214 10.2611 43.9678 9.81894 43.1292 9.70031C41.422 9.47384 39.8189 10.706 38.9611 12.1753C38.1034 13.6447 37.7828 15.3567 37.2155 16.9609C36.1029 20.0964 34.0531 22.806 32.3869 25.6935C30.7208 28.581 29.4054 31.8944 29.9809 35.1594C30.0521 35.5584 30.2686 36.0518 30.6824 36.0275C30.8879 35.9925 31.0743 35.8873 31.2113 35.731C31.9046 35.0974 32.6034 34.4611 33.2858 33.8113C36.7825 30.4898 39.9449 26.8501 42.7374 22.9354C44.6803 20.2177 46.7931 15.176 46.7931 15.176Z"
        fill="#FC9658"
      />
      <path
        d="M30.666 35.6507C32.7789 33.51 33.5653 30.4203 32.7323 27.5517C32.4144 26.4597 31.88 25.4137 31.6553 24.3056C31.4333 23.1975 31.5868 21.9573 32.4582 21.232C33.3324 20.5068 35.0397 20.647 35.5631 21.6796C35.9303 22.4075 35.7275 23.4428 36.4948 23.8365C36.9908 24.0872 37.6019 23.8634 37.9171 23.4725C38.2322 23.0816 38.3172 22.5693 38.3966 22.0786L39.1913 17.0369C39.29 16.4222 39.3941 15.786 39.7531 15.2737C40.1121 14.7615 40.7972 14.3948 41.4659 14.5646C43.3293 15.0365 42.436 18.2394 44.2035 18.9943C45.4339 19.52 46.5657 18.3014 47.1028 17.2095C47.8071 15.7887 48.4401 14.233 49.8158 13.3298C51.1915 12.4266 53.6112 12.6208 54.2991 14.1225C54.7595 15.12 54.2854 16.2173 53.7455 17.1259C53.1317 18.1639 52.4329 19.148 51.6574 20.0754C50.8955 20.9867 50.0542 22.3024 50.9311 23.2137C51.4463 23.7529 52.3507 23.8365 52.8933 24.3487C53.192 24.6588 53.3783 25.0605 53.4194 25.4838C53.6989 27.5624 51.3367 29.4146 49.1198 28.8512C47.7989 28.5142 46.5109 27.4896 45.2229 27.8213C43.9596 28.1448 43.5568 29.6384 43.8363 30.8517C44.1158 32.0649 44.8585 33.1784 45.1215 34.3943C45.3846 35.6102 44.9242 37.0958 43.6363 37.3708C41.9372 37.732 40.0984 35.7612 38.5309 36.4325C38.0513 36.6374 37.7362 37.0553 37.4457 37.4597C36.6154 38.6137 35.8152 39.7892 35.0479 40.9835L30.666 35.6507Z"
        fill="#689399"
      />
      <path
        d="M20.6745 10.5038C19.2906 9.71927 17.8847 8.92392 16.7612 7.80775C15.6376 6.69157 14.8045 5.19525 14.8457 3.62343C14.8868 2.05162 15.961 0.463631 17.523 0.140101C19.4468 -0.258918 21.2828 1.31559 22.0447 3.1004C22.8065 4.8852 22.8092 6.8776 23.1984 8.77294C23.3244 9.39304 23.582 10.0994 24.1876 10.2854C24.8481 10.485 25.4866 9.9754 25.9059 9.44696C27.1281 7.90211 27.6762 5.85848 29.1779 4.57785C29.7314 4.10603 30.5152 3.75285 31.1866 4.03863C31.5045 4.195 31.773 4.43226 31.9676 4.72613C33.1076 6.31682 33.2419 8.40358 32.3101 10.121C31.6634 11.2749 30.5727 12.1511 29.9726 13.3293C29.8383 13.5962 29.7287 13.9117 29.8465 14.184C29.9644 14.4563 30.2768 14.6019 30.5673 14.6908C31.499 14.9739 32.5047 14.9712 33.431 15.2732C34.3572 15.5725 35.2615 16.3274 35.2341 17.2872C35.2067 18.247 34.2723 18.9533 33.3405 19.2364C32.4088 19.5195 31.3976 19.5384 30.4988 19.9077C29.5972 20.2771 28.797 21.1911 29.0381 22.1158C29.3067 23.135 30.6193 23.4962 31.6771 23.3506C32.7349 23.2051 33.7571 22.7359 34.8231 22.8195C36.4865 22.9543 37.7224 24.3913 37.5854 26.0251C37.5169 26.834 37.1168 27.5808 36.4728 28.093C35.7822 28.6403 34.8916 28.8614 34.0229 29.0501C29.5067 30.0288 24.8919 30.5033 20.2661 30.4602C19.6249 30.4602 18.9672 30.4332 18.3698 30.2068C16.6653 29.557 16.0514 27.5376 15.6568 25.7825C15.465 24.9386 15.306 23.9546 15.8706 23.2913C16.6242 22.407 18.0437 22.7225 19.2029 22.5445C20.173 22.3881 21.0362 21.8408 21.5788 21.032C21.9049 20.5737 22.0611 20.0183 22.02 19.4602C21.9049 18.4411 20.9595 17.6916 19.9811 17.3492C19.0028 17.0068 17.9396 16.9259 16.953 16.6024C15.7582 16.2087 14.6429 15.3729 14.2428 14.2001C13.8427 13.0274 14.325 11.5337 15.4869 11.0538C17.1613 10.3636 19.0933 11.9327 20.8252 11.3989C21.3322 10.9541 20.6745 10.5038 20.6745 10.5038Z"
        fill="#66A896"
      />
      <path
        d="M18.9837 28.7941C9.17585 27.0389 0.987571 16.0982 0.987571 16.0982L-0.00170898 15.8906C-0.00170898 15.8906 1.54113 26.6453 15.6349 31.1504C32.5678 36.5749 38.7117 25.2406 38.7117 25.2406L39.6654 23.5259C38.287 25.7259 33.2611 31.3446 18.9837 28.7941Z"
        fill="#E69E19"
      />
      <path
        d="M42.5263 18.673C42.6743 18.1661 42.4277 17.6269 41.9426 17.3977C41.3178 17.1416 40.9068 17.821 40.841 17.9261C40.5259 18.4141 39.0406 21.6764 38.3363 22.0754C35.6452 23.5987 30.3645 25.8553 19.4906 23.1835C13.7331 21.7708 2.01519 14.645 2.01519 14.645L0.987549 16.0955C0.987549 16.0955 9.16761 27.0362 18.9727 28.7913C35.9686 31.8271 39.8572 23.2886 40.0929 22.7279C40.1038 22.7063 42.4743 18.8428 42.5263 18.673Z"
        fill="#FDB62F"
      />
      <path
        d="M-0.00170898 15.8906C-0.00170898 15.8906 0.927282 16.1198 0.987571 16.0955C1.04786 16.0712 2.00974 14.645 2.00974 14.645C2.00974 14.645 1.27805 14.2595 0.919061 14.5237C0.409349 14.9092 -0.00170898 15.8906 -0.00170898 15.8906Z"
        fill="#6B4D3A"
      />
      <path
        d="M25.8565 18.9776C24.2342 17.2171 20.9704 16.2492 18.9535 17.0202C16.9393 17.7886 17.6217 17.6565 16.9366 17.7886C16.2515 17.9207 15.0293 17.5568 13.7769 18.3656C12.3601 19.2769 9.65262 21.9136 10.7049 25.6693C11.6065 28.883 15.1087 31.8379 19.655 31.4308C24.2013 31.0237 26.6266 28.5756 27.361 26.3999C28.2324 23.8413 27.4213 20.6788 25.8565 18.9776Z"
        fill="#FD6C14"
      />
      <path
        d="M18.4357 19.9673C18.4357 19.9673 18.9426 15.6051 16.216 14.373C13.4893 13.1408 9.06081 11.4423 9.24715 11.7146C9.4335 11.9896 10.1378 16.8156 11.9327 18.2418C14.6539 20.4014 18.4357 19.9673 18.4357 19.9673Z"
        fill="#1F6771"
      />
      <path
        d="M19.3371 19.5955C19.3371 19.5955 19.0932 17.436 19.1151 16.1634C19.1562 13.5482 19.3207 12.2676 19.3207 12.2676L17.0461 12.4509C17.0461 12.4509 17.1147 15.5029 17.2873 16.6379C17.5915 18.6546 18.1615 19.8759 18.1615 19.8759C18.1615 19.8759 18.5698 20.2345 18.9535 20.0646C19.1645 19.9945 19.3124 19.8112 19.3371 19.5955Z"
        fill="#6B4D3A"
      />
      <path
        d="M18.2489 12.8965C18.8771 12.8231 19.357 12.5203 19.3207 12.2201C19.2845 11.92 18.7459 11.7361 18.1177 11.8095C17.4895 11.8829 17.0096 12.1857 17.0458 12.4859C17.082 12.786 17.6207 12.9698 18.2489 12.8965Z"
        fill="#8A634B"
      />
      <path
        d="M46.6287 49.7585H9.76776C8.63872 49.7558 7.72343 48.8553 7.72069 47.7445L2.20703 27.9256C2.20977 26.8148 3.12506 25.9143 4.2541 25.9116H52.1369C53.2659 25.9143 54.1812 26.8148 54.1839 27.9256L48.673 47.7445C48.6703 48.8553 47.7577 49.7558 46.6287 49.7585Z"
        fill="#A1C1F0"
      />
      <path
        d="M9.76777 49.7585H46.6287C47.7578 49.7558 48.6703 48.8553 48.673 47.7445L51.1469 39.4401C51.1442 38.3293 50.035 41.0581 49.479 42.1369L48.3671 43.7545L46.1433 47.5286L9.76777 47.7445H7.7207C7.72344 48.8553 8.63873 49.7558 9.76777 49.7585Z"
        fill="#689CEB"
      />
      <g opacity="0.75">
        <path
          opacity="0.85"
          d="M44.0666 53.9993H34.8287C33.9025 53.9993 33.1352 53.2956 33.0639 52.3871L31.5238 31.9885C31.5074 31.7647 31.6773 31.5706 31.9047 31.5518C31.9157 31.5518 31.9266 31.5518 31.9376 31.5518H46.9631C47.1906 31.5518 47.3769 31.7324 47.3769 31.9562C47.3769 31.967 47.3769 31.9777 47.3769 31.9885L45.8341 52.3871C45.7629 53.2956 44.9928 53.9993 44.0666 53.9993Z"
          fill="#E0F0FF"
        />
        <path
          opacity="0.85"
          d="M45.2558 51.7483L46.289 37.5049H32.6062L33.6393 51.7483C33.7051 52.5733 34.4039 53.2095 35.2452 53.2095H43.6527C44.4913 53.2095 45.1928 52.5733 45.2558 51.7483Z"
          fill="#AABDE3"
        />
      </g>
    </svg>
  );
}

export default FoodGraphic;
