import React, { useState } from 'react';
import { Grid, Typography, Paper, TextField, Box, Link } from '@mui/material';
import { Button } from '../../components/Button/Button'; // Your custom button component
import { Link as RouterLink } from 'react-router-dom';

function VerificationPage(
  { handleReturn }
) {
  const verificationCode = ''

  const handleConfirm = () => {
    // Handle confirmation logic
  };

  const handleResendCode = () => {
    // Handle code resend logic
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={2}
        sx={{
          margin: '50px',
          padding: '32px',
          maxWidth: '500px',
          width: '100%',
          borderRadius: '1rem',
          boxSizing: 'border-box',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ fontSize: '2.4rem', fontWeight: 700, textAlign: 'center', marginBottom: '1rem' }}>Check for Verification Code</div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ fontSize: '1.6rem', textAlign: 'center', marginBotton: '1.8rem' }}>A 6-digit code has been sent to e***@gmail.com</div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label=""
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: '1.2rem',
                      fontSize: '1.8rem',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#00233D',
                        borderWidth: '2px',
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="primary"
                onClick={handleConfirm}
                label="Confirm"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant="link"
                onClick={handleReturn}
                to="/"
                sx={{ minWidth: '150px' }}
                label="Back"
              />
              <Button
                variant="link"
                component={handleResendCode}
                to="/"
                sx={{ minWidth: '150px' }}
                label="Resend code"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default VerificationPage;
