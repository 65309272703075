import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_SERVER_HOST + process.env.REACT_APP_SERVER_API_PATH,
  headers: { FCI_app: process.env.REACT_APP_APP_NAME },
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_SERVER_HOST + process.env.REACT_APP_SERVER_API_PATH,
  headers: { FCI_app: process.env.REACT_APP_APP_NAME, 'Content-Type': 'application/json' },
  withCredentials: true
});

export const fetchData = async (endpoint) => {
  try {
    const response = await axiosPrivate.get(endpoint);
    if (response?.data?.payload) return response?.data?.payload;
    throw new Error({ errCode: response?.data?.errCode, errMsg: response?.data?.errMsg });
  } catch (err) {
    throw new Error({ errCode: err?.response?.data?.errCode, errMsg: err?.response?.data?.errMsg });
  }
}

export const postData = async (endpoint) => {
  try {
    const response = await axiosPrivate.post(endpoint);
    if (response?.data?.payload) return response?.data?.payload;
    throw new Error({ errCode: response?.data?.errCode, errMsg: response?.data?.errMsg });
  } catch (err) {
    throw new Error({ errCode: err?.response?.data?.errCode, errMsg: err?.response?.data?.errMsg });
  }
}
