import React from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';
import { Pills } from '../Pills/Pills';
import TimeGraphic from '../../resources/illustrations/TimeGraphic';
import Cookies from 'universal-cookie';
import { distanceActions } from '../../store/distance-slice';
import { useDispatch, useSelector } from 'react-redux';
import getDistance from 'geolib/es/getDistance';
import { coordsActions } from '../../store/coords-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';

export function Table({ website, email, phone, addressObj, addressString, hours, lat, lng, resourceData }) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const location = cookies.get('location');
  const locationCoords = useSelector((state) => state.coords.coordinates);

  console.log(hours);

  const totalDist =
    locationCoords && lat
      ? `${(
        getDistance(
          {
            latitude: locationCoords.lat,
            longitude: locationCoords.lng,
          },
          { latitude: lat, longitude: lng }
        ) * 0.000621371
      ).toFixed(1)}  miles away`
      : '';

  function handleClick(e) {
    if (locationCoords) {
      e.stopPropagation();
    } else {
      const successCallback = (position) => {
        const cookies = new Cookies();
        cookies.set('locationPermissionGranted', 'true', { path: '/' });
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        cookies.set('location', JSON.stringify(location), { path: '/' });
        dispatch(enteredLocationActions.handleLocationInput('Current Location'));
        dispatch(coordsActions.logCoords(location));
        dispatch(distanceActions.toggle(true));
      };
      const errorCallback = (error) => {
        if (error.code === 1) {
          alert('Please allow your location access');
        }
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
        enableHighAccuracy: true,
        timeout: 5000,
      });
    }
  }

  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  function isDST(d) {
    const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();
  }

  const getHours = (time) => time.slice(0, 2);

  const getMinutes = (time) => time.slice(-2);

  const convertTime = (hours, minutes) => {
    const timeOfDay = (hours < 12 && 'AM') || (hours >= 12 && 'PM');
    minutes = minutes.toString().padStart(2, '0');
    return `${hours > 12 ? hours - 12 : hours.replace(0, '')}:${minutes} ${timeOfDay}`;
  };

  const getTimeInFl = () => {
    const d = new Date();
    const currentDay = weekdays[d.getDay()];
    const currentHour = d.getUTCHours();
    const currentMinute = d.getUTCMinutes();
    return [currentDay, currentHour, currentMinute];
  };

  const isOpen = (timeObj) => {
    // Get current day, hour, and minute in FL
    const [weekdays, hour, minute] = getTimeInFl();

    // Check if open today => returns a bool
    const openToday = timeObj.dayOfWeek.toLowerCase() === weekdays.toLowerCase();

    const hoursToOpen = getHours(timeObj.timeOpen) - 0 - hour;

    const minutesToOpen = getMinutes(timeObj.timeClosed) - 0 - minute;

    // Check if open this hour => returns a number
    const hoursToClose = getHours(timeObj.timeClosed) - 0 - hour;

    // Check if open this minute => returns a number
    const minutesToClose = getMinutes(timeObj.timeClosed) - 0 - minute;

    // Case 1: business is closed today
    if (!openToday) {
    }

    // Case 2: business is open today
    else if (openToday) {
      // Case 2a: business not past opening hour
      if (hoursToOpen > 0) {
      }

      // Case 2b: business past opening hour
      else if (hoursToOpen <= 0) {
        // Case 2b1 business is not past opening minute
        if (minutesToOpen > 0) {
          return;
        }

        // Case 2b2 business is past opening minute
        if (minutesToOpen <= 0) {
          // Case 2b2a: business has passed closing hour
          if (hoursToClose < 0) {
          }

          // Case 2b2b: business is before closing hour
          else if (hoursToClose > 0) {
            return (
              <Pills
                variant="success"
                text="Open now"
                icon="time-outline"
                useBackground={false}
              />
            );
          }

          // Case 2b2c: business closes this hour
          else if (hoursToClose === 0) {
            // Case 3a: business is closed
            if (minutesToClose <= 0) {
              return <Pills variant="general" text="Closed" useBackground={false} />;
            }

            // Case 3b: business is not closing within 30 mintues
            if (minutesToClose > 30) {
              return (
                <Pills
                  variant="success"
                  text="Open now"
                  icon="time-outline"
                  useBackground={false}
                />
              );
            }

            // Case 3c: business is closing within 30 minutes
            if (minutesToClose <= 30) {
              return (
                <Pills
                  variant="warning"
                  text="Closing soon"
                  icon="time-outline"
                  useBackground={false}
                />
              );
            }
          }
        }
      }
    }
  };

  return (
    <div className="table">
      <div className="table__section">
        {website ? (
          <a
            href={
              website.startsWith('http://') || website.startsWith('https://')
                ? website
                : 'http://' + website
            }
            rel="noreferrer"
            target="_blank"
            className="table--blue"
          >
            {website}
          </a>
        ) : (
          'N/A'
        )}
        <IonIcon name="earth" className="table__icon" />
      </div>
      {email &&
        <div className="table__section">
          <a href={`mailto:${email}`} className="table--blue">
            {email}
          </a>
          <IonIcon name="mail-outline" className="table__icon" />
        </div>
      }
      {phone &&
        <div className="table__section">
          {phone}
          <IonIcon name="call-outline" className="table__icon" />
        </div>
      }
      {(addressObj || addressString) && (
        <div className="table__section">
          <div className="table__directions">
            <div className="table__blue" onClick={handleClick}>
              <a
                href={`https://www.google.com/maps/place/${encodeURIComponent(`${addressObj?.street}, ${addressObj?.city}, ${addressObj?.county}, ${addressObj?.state}, ${addressObj?.zip}`)}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Get Directions
              </a>
            </div>
            {addressObj ? (
              <>
                {addressObj?.street && <div className="table__address">{addressObj?.street}</div>}
                <div className="table__address">{`${addressObj?.city}, ${addressObj?.county}, ${addressObj?.state}`}</div>
                <div className="table__address">{addressObj?.zip}</div>
              </>
            ) : (
              <div className="table__address">{addressString}</div>
            )}
            <div className="table__distance">{totalDist}</div>
          </div>
          <IonIcon name="navigate-circle-outline" className="table__icon" />
        </div>
      )}
      {hours && (
        <div className="table__hours">
          <span>Hours</span>
          <br />
          {/* {hours && (
                    <div className="table__section">
                        <table className="table__hours--table">
                            <tbody>
                                {hours &&
                                    hours.map((day, index) => (
                                        <tr key={index}>
                                            <td className="table__hours--table-left">
                                                {weekdays[index]}
                                            </td>
                                            <td className="table__hours--table-right">
                                                {day.openTime && (
                                                    <>
                                                        <span>
                                                            {convertTime(
                                                                day.openTime.hours,
                                                                day.openTime.minutes
                                                            )}
                                                            {' - '}
                                                            {convertTime(
                                                                day.closeTime.hours,
                                                                day.closeTime.minutes
                                                            )}
                                                        </span>

                                                        <span className="timeColor">
                                                            <TimeGraphic />{' '}
                                                            <span className="timeColor__text">
                                                                open now
                                                            </span>
                                                        </span>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )} */}
          <br />
          <br />
        </div>
      )}
      <div className="table__section">
        <a className="table--blue" href={`https://docs.google.com/forms/d/e/1FAIpQLSdp335KVGMHuzun8oAyifIh3UF8KHqxXyedxLQBf_xql1lewQ/viewform?usp=pp_url&entry.1234772893=${resourceData?.resourceId}&entry.1668906360=${encodeURIComponent(resourceData?.name)}&entry.1621553103=${encodeURIComponent(resourceData?.website)}&entry.1889257640=${resourceData?.email}&entry.34692080=${resourceData?.phone?.number}&entry.292274552=${encodeURIComponent(resourceData?.address?.street)}&entry.1586140992=${encodeURIComponent(resourceData?.address?.city)}&entry.89590480=FL&entry.1075162968=${encodeURIComponent(resourceData?.address?.zip)}&entry.1298883112=${encodeURIComponent(resourceData?.description)}`} target="_blank" color="#233b5f">Suggest Edits</a>
        <IonIcon name="alert-circle-outline" className="table__icon" />
      </div>

    </div>
  );
}

Table.propTypes = {
  website: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  // hours: PropTypes.arrayOf(
  //     PropTypes.shape({
  //         open: PropTypes.shape({
  //             hours: PropTypes.number,
  //             minutes: PropTypes.number,
  //         }),
  //         close: PropTypes.shape({
  //             hours: PropTypes.number,
  //             minutes: PropTypes.number,
  //         }),
  //     })
  // ),
  hours: PropTypes.string,
};
