import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { validateForm } from './formValidation';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { RegistrationForm } from './RegistrationForm';
import { PendingApproval } from './PendingApproval';
import { MyResources } from './MyResources';
import { ManageUsers } from './ManageUsers';
import { ManageResources } from './ManageResources';
import { Account } from './Account'
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';


import {
  Box,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import {
  Button,
  Footer,
  Header,
  MyResourcesList,
  StyledDropzone,
  HoursModal,
  PendingResourcesList,
  UsersList,
  ApprovedResourcesList,
  Map,
} from '../../components';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

const defaultTheme = createTheme();


const Dashboard = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);


  const getTabValue = () => {   // Returns number after # mark, or 0 if none present (when the dashboard first loads)

    if (window.location.hash.substring(1) === "")
      return 0;
    return parseInt(window.location.hash.substring(1), 10);

  }

  const [tab, setTab] = React.useState(getTabValue());  // Sets the initial tab based on the href

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };




  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }




  return (
    <fragment>
      <Header dashboard={true} />

      <ThemeProvider theme={defaultTheme}     /* Fix indents later*/>
        <Container component="main" maxWidth="lg" style={{ padding: '2rem 0' }}>
          <div className="signup__heading">
            <br></br>
            <p className="p1" style={{ fontSize: "40px", fontWeight: "700", color: '#00233D', marginBottom: '-0.5rem' }}>{t('Dashboard')}</p>
          </div>
        </Container>
        {
          // Fix: separate behavior for admin user and regular approved user
          user?.roles?.includes('100999') ? (
            <Container component="main" maxWidth="lg" style={{ padding: '2rem 0' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab href="#0" User label={`${t('My')} ${t('Resource.Plural')}`} {...a11yProps(0)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                  <Tab href="#1" label={`${t('Manage Resources')}`} {...a11yProps(1)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                  <Tab href="#2" label={`${t('Manage')} ${t('User.Plural')}`} {...a11yProps(2)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                  <Tab href="#3" label={t('Account')} {...a11yProps(3)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                </Tabs>
              </Box>
              {tab === 0 && (
                <MyResources />
              )}
              {tab === 1 && (
                <ManageResources />
              )}
              {tab === 2 && (
                <ManageUsers />
              )}
              {tab === 3 && (
                <Account />
              )}





            </Container>
          ) : (user.roles.includes('100901')) ?
            (
              <Container component="main" maxWidth="lg" style={{ padding: '2rem 0' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab href="#0" label={`${t('My')} ${t('Resource.Plural')}`} {...a11yProps(0)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                    <Tab href="#1" label={`${t('Manage Resources')}`} {...a11yProps(1)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                    <Tab href="#2" label={t('Account')} {...a11yProps(3)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                  </Tabs>
                </Box>
                {tab === 0 && (
                  <MyResources />
                )}
                {tab === 1 && (
                  <ManageResources />
                )}
                {tab === 2 && (
                  <Account />
                )}

              </Container>
            ) : (user.roles.includes('100100')) ?
              (
                <Container component="main" maxWidth="lg" style={{ padding: '2rem 0' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                      <Tab href="#0" label={`${t('My')} ${t('Resource.Plural')}`} {...a11yProps(0)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} />
                      <Tab href="#1" label={t('Account')} {...a11yProps(3)} sx={{ fontFamily: "Nunito", fontSize: 11, fontStyle: "normal", fontWeight: 600, }} /* Could change to 1, or even change order of tabs */ />
                    </Tabs>
                  </Box>
                  {tab === 0 && (
                    <MyResources />
                  )}
                  {tab === 1 && (
                    <Account />
                  )}

                </Container>
              ) : (user.roles.includes('100002')) ? (
                <Box>
                  <PendingApproval />
                </Box>
              ) : (
                <Box>
                  <RegistrationForm />
                </Box>
              )
        }
      </ThemeProvider>
      <Footer />
    </fragment>
  );
}
export { Dashboard };
