import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResourceMap, Toggle, FilterButton, Pagination1 } from '../';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export const SearchResponsive = ({
  pins,
  cards,
  setEditingFilters,
  totalPages,
  setActive,
  locationCoords,
}) => {
  const { t } = useTranslation();
  const [map, setMap] = useState(false);
  const enteredTerm = useSelector((state) => state.enteredTerm.input);
  const enteredLocation = useSelector((state) => state.enteredLocation.input);
  const filterInputs = useSelector((state) => state.filterInputs.filters);
  const cookies = new Cookies();
  const currentCoords = cookies.get('location');

  let total = 0;
  Object.keys(filterInputs).forEach((key) => {
    if (typeof filterInputs[key] === 'boolean' && filterInputs[key]) {
      total++;
    }
  });
  const mapHandler = (map) => {
    setMap(map);
  };
  return (
    <>
      {enteredTerm && (
        <div className="heading-primary" style={{ height: 'max-content' }}>
          {`"${enteredTerm}" ${locationCoords ? `near ${enteredLocation}` : ''}`}
        </div>
      )}

      <div className="search-page--responsive__bar">
        <FilterButton
          defaultValue="Filters"
          filters={total}
          selectedValue="selected"
          setEditingFilters={setEditingFilters}
        />
        <Toggle onMap={mapHandler} />
      </div>
      {map ? (
        <div className="search-page--responsive__map">
          <ResourceMap pins={pins} />
        </div>
      ) : (
        <>
          <div className="search-page--responsive__results">
            {cards.length > 0 ? (
              cards
            ) : (
              <div className="search-page--responsive__loading">
                <p className="p1search">
                  {t('Oops... we didn\'t find anything that matches this search.')}
                </p>
                <p>
                  {t('Try to search for something more general, change the filters or check for spelling mistakes.')}
                </p>
              </div>
            )}
          </div>
          <Pagination1 totalPages={totalPages} setActive={setActive} />
        </>
      )}
    </>
  );
};

SearchResponsive.propTypes = {
  pins: PropTypes.array,
};

SearchResponsive.defaultProps = {
  pins: [],
};
