import { createSlice } from '@reduxjs/toolkit';
const queryParams = new URLSearchParams(window.location.search);
const searchInput = queryParams.has('search') ? queryParams.get('search') : '';
const enteredTermSlice = createSlice({
    name: 'eneteredTerm',
    initialState: { input: searchInput },
    reducers: {
        handleSearchInput: (state, action) => {
            state.input = action.payload;
        },
    },
});
export const enteredTermActions = enteredTermSlice.actions;
export default enteredTermSlice.reducer;
