import { createSlice } from '@reduxjs/toolkit';

const queryParams = new URLSearchParams(window.location.search);
const urlPage = queryParams.has('page') ? queryParams.get('page') : 1;

const currentPageSlice = createSlice({
    name: 'currentPage',
    initialState: { page: urlPage },
    reducers: {
        pageHandler: (state, action) => {
            state.page = action.payload;
        },
    },
});
export const currentPageActions = currentPageSlice.actions;
export default currentPageSlice.reducer;
